import React, {useState} from 'react'
import Image from '../../images/triangulo.svg'
import { HeroContainer, HeroBg, ImageBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements'
import { Button } from '../ButtonElements'

const Hero = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <ImageBg src={Image} alt="" />
      </HeroBg>
      <HeroContent>
        <HeroH1> Abriendo las puertas <br></br>a la seguridad financiera</HeroH1>
        <HeroP>Ayudamos a identificar, validar y asegurar tus operaciones de forma rápida y eficiente.</HeroP>
        <HeroBtnWrapper>
          <Button to='products' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            explorar soluciones {hover ? <ArrowForward /> : <ArrowRight/>}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
