import styled from 'styled-components'

export const OnboardingContainer = styled.div`
  display: block;
  width: 100%;
 `
 export const OnboardingSection  = styled.div`
 height: auto;
 background: #fff;
 display: grid;
 grid-template-columns: 100%;
 align-items: center;
 width: 100%;
 border-top-left-radius: 18px;
 border-top-right-radius: 18px;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
 export const Section1  = styled.div`
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
 export const Section2  = styled.div`
 align-items: center;
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`

export const OnboardingH2 = styled.h2`
  font-size: 1.5rem;
  color: #039F1C;
  text-align: center;
  
  @media screen and (max-width: 420px) {
    font-size: 1rem;
  }
`

export const OnboardingP = styled.p`
  font-size: 0.9rem;
  text-align: justify;
  color: #282C47;
  @media screen and (max-width: 420px) {
    font-size: 0.7rem;
  }
`

export const OnboardingIcon  = styled.img`
  width: 20%;
  margin-left: auto;
    margin-right: auto;
    display:block;
    
`
export const OnboardingImage  = styled.img`
  width: 20%;
  margin-left: auto;
    margin-right: auto;
    display: block;

`
export const BriefSection = styled.div`
display: grid;
grid-template-columns: 30% 70%;
align-items: center;
 `

 export const Section3  = styled.div`
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
 export const Section4  = styled.div`
 align-items: center;
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
export const BriefImage  = styled.img`
  width: 100%;
  margin-left: auto;
    margin-right: auto;
    display: block;

`

export const OnboardingH2B = styled.h2`
font-size: 1.2rem;
margin-bottom: 10px;
margin-top: 10px;
color: #282C47;
text-align: center;
`
export const OnboardingH2P = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #039F1C;
  text-align: center;
`
export const OnboardingPP = styled.p`
  font-size: 1rem;
  text-align: justify;
  color: #282C47;
  line-height:18px;
`
 export const PriceSection = styled.div`
 height: auto;
 background: #282C47;
 display: grid;
 grid-template-columns: 100%;
 align-items: center;
 `
 export const OnboardingPP1 = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #fff;
  line-height:25px;
`
 export const OBBtnWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 420px) {
    margin-top: 0px;
  }
`
export const Divider = styled.hr`
border: 0;
border-top: 3px solid #039F1C;
width:60%;
margin-left: 60px;
@media screen and (max-width: 960px) {
  margin-left: 100px;
}
@media screen and (max-width: 768px) {
  margin-left: 0px;
  width:100%
}
`
export const Section5  = styled.div`
 align-items: center;
 width:90%;
 margin-left: auto;
 margin-right: auto;
 display: block;
 margin-top:4%;
 margin-bottom:4%;
 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
 export const Section6  = styled.div`
 align-items: center;
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
export const OnboardingImageP  = styled.img`
  width: 25%;
  margin-left: auto;
    margin-right: auto;
    display: block;
    @media screen and (max-width: 420px) {
      width: 20%;
      margin-top: 6px;
    }
`

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #2C2C44;
`
export const FormInput = styled.input`
  padding: 4px 4px;
  margin-bottom: 8px;
  width:90%;
  border: 0;
  font-size: 0.8rem;
  border-bottom: 1px solid gray;
  background: transparent;
  outline: 0;
  &:focus{
    box-shadow:none;
    padding-bottom: 6px;  
  font-weight: 400;
  border-width: 1px;
  border-image: linear-gradient(to right, #039F1C,#039F1C);
  border-image-slice: 1;
  }

`

export const BriefForm = styled.div`
display: grid;
grid-template-columns: 100%;
`
export const Status = styled.div`
display: ${({ isShown }) => isShown ? 'block' : 'none'};
 width:100%;
 font-size:0.8rem;
 color:red;
 margin-bottom:2%;
 `
export const FormButton = styled.button`
  background: #039F1C;
  width: 100%;
  padding: 8px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  -ms-transform: translateY(-50%);
  margin-bottom: 3%;
`