import styled from 'styled-components'
import Popup from 'reactjs-popup';

export const StyledPopup = styled(Popup)`
@media screen and (max-width: 720px) {
    display: none;
   }
`;
export const ModalContainer = styled.div`
width:80%;
margin-left: auto;
margin-right: auto;
display: block;
@media screen and (max-width: 720px) {
    display: none;
   }
 `
export const Background = styled.div`
@media screen and (min-width: 720px) {
    background: rgba(0, 0, 0, 0.97);
    width:100%;
    height: 625%;
    margin-top: -120%;
    position: absolute;
    display: ${({ modal }) => modal ? '' : 'none'};
}
 `
 export const Close = styled.button`
 cursor: pointer;
 color:#fff;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 30px;
  right: 8.5%;
  top: -4%;
  font-size: 48px;
  background: #4FBA04;
  border-radius: 50%;
  `
export const ProductsContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  
 `

 export const ProductContainer = styled.div`
 display: none;
 width:100%;
 @media screen and (max-width: 720px) {
  display: ${({ isShown }) => isShown ? 'block' : 'none'};
 }
 `
export const ProductsWrapper = styled.div`
  display: ${({ isShown }) => isShown ? 'block' : 'grid'};
  margin: ${({ isShown }) => isShown ? '0rem' : '2rem'};
  gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
  @media screen and (min-width: 720px) {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
} 
`
export const ProductsH1 = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2%;
  text-align:center;
  color: #4FBA04;
`
export const CardC = styled.div`
  box-shadow: 0 0 10px 5px #e1e5ee;
  min-width: 28rem;
  display: flex;
  border-radius: 5px;
  @media screen and (min-width: 720px) {
    flex-direction: column;
    text-align: center;
    min-width: 80%;
    min-height: 26rem;
    max-height: 26rem;
} 
@media screen and (max-width: 420px) {
    min-width: 100%;
} 
`
export const CardImageContainer = styled.div`
    min-width: 30%;
    max-width: 30%;
    background-color: #C1C5D0;
    display: flex;
    justify-content: center;
    @media screen and (min-width: 720px) {
        min-width: 100%;
        max-width: 100%;
    } 
`
export const CardImage = styled.img`
    width: 14rem;
    height: 11rem;
    margin: auto;
    @media screen and (min-width: 720px) {
        width: 100%;
        height: 100%;
    }  
`
export const CardBody = styled.div`
    margin: 1rem;
    flex-grow: 1;
    max-height:50%;
    min-height:50%;
`
export const CardTitle = styled.h2`
    line-height: 1.4rem;
    margin-bottom: 1rem;
    font-size: 1.2em;
    color: #000;
    @media screen and (min-width: 720px) {
        font-size: 0.8em;
        max-height:14%;
        min-height:14%;
    } 
`
export const CardDescription = styled.p`
    line-height: 1.2rem;
    color: #000;
    font-size: 0.8em;
    @media screen and (min-width: 720px) {
        max-height:42%;
    min-height:42%;
    } 
    
    
`
export const CardButton = styled.button`
border: 1px solid #4FBA04;
background-color: #4FBA04;
font-family: inherit;
font-size: 1rem;
font-weight: bold;
color: #fff;
width: 100%;
padding-top: 0.4rem;
padding-bottom: 0.4rem;
cursor: pointer;
border-radius: 5px;
&:hover {
    transition: all 0.2s ease-in-out;
    background: #83C77F;
    color: #010606;
  }
`
