import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarLinkExt,
  SidebarRoute, SideBtnWrap } from './SidebarElements'
import { FaWhatsapp } from 'react-icons/fa'

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='features' onClick={toggle}>Acerca de </SidebarLink>
          <SidebarLink to='products' onClick={toggle}>Productos</SidebarLink>
          <SidebarLink to='/survey' onClick={toggle}>Soporte</SidebarLink>
          <SidebarLinkExt href='https://tesseractmx.zendesk.com/hc/es' target='_blank' onClick={toggle}>Contactanos</SidebarLinkExt>
          <SidebarLinkExt href='https://api.whatsapp.com/send/?phone=5578269422&text&app_absent=0' target='_blank' onClick={toggle}>
            <FaWhatsapp /></SidebarLinkExt>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to='/api-doc'>Developers</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
