import React from 'react'
import thales from '../../images/socios/logo-thales.png'
import efisys from '../../images/socios/efisys.svg'
import finnovista from '../../images/socios/finnovista.svg'
import kippeo from '../../images/socios/kippeo.png'
import lynx from '../../images/socios/lynx.png'
import startup from '../../images/socios/startup.png'
import yoconsulting from '../../images/socios/yo-consulting.png'
import conecta from '../../images/socios/conecta.webp'
import endcom from '../../images/socios/endcom.svg'
import {PartnersContainer, PartnersH1, PartnersWrapper, PartnersIcon, IconLink} from './PartnerElements'

const Partners = () => {
  return (
    <PartnersContainer id='partnerts'>
      <PartnersH1>Nuestros Partners</PartnersH1>
      <PartnersWrapper>
        <IconLink href="https://www.finnovista.com/" target="_blank"><PartnersIcon src={finnovista}></PartnersIcon></IconLink>
        <IconLink href="https://kippeo.com/" target="_blank"><PartnersIcon src={kippeo}></PartnersIcon></IconLink>
        <IconLink href="http://lynxworxs.com/" target="_blank"><PartnersIcon src={lynx} style={{width: "45%"}}></PartnersIcon></IconLink>
        <IconLink href="https://www.startupbootcamp.org/accelerator/fintech-mexico-city/" target="_blank"><PartnersIcon src={startup}></PartnersIcon></IconLink>
        <IconLink href="https://www.thalesgroup.com/en" target="_blank"><PartnersIcon src={thales}></PartnersIcon></IconLink>
        <IconLink href="https://www.yoconsulting.net/" target="_blank"><PartnersIcon src={yoconsulting}></PartnersIcon></IconLink>
        <IconLink href="https://www.conecta.mx/es/" target="_blank"><PartnersIcon src={conecta}></PartnersIcon></IconLink>
        <IconLink href="https://www.efisys.com.mx/" target="_blank"><PartnersIcon src={efisys}></PartnersIcon></IconLink>
        <IconLink href="https://endcom.mx/" target="_blank"><PartnersIcon src={endcom}></PartnersIcon></IconLink>
      </PartnersWrapper>
    </PartnersContainer>
  )
}

export default Partners
