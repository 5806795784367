import styled from 'styled-components'

export const PartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #282C47;
   width: 100%;
  
 `
export const PartnersWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;
    margin-top:3%;
    margin-bottom:3%;
    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr 1fr;
  }

    @media screen and (max-width: 786px) {
      grid-template-columns: 1fr;
      padding: 0 20px;
      margin-bottom:180px;
  }
`

export const PartnersIcon = styled.img`
  width: 40%;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  
`

export const PartnersH1 = styled.h1`
  font-size: 2.5rem;
  margin-top:3%;
  color: #4FBA04;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`

    

export const IconLink = styled.a`
 
`