import React from 'react'
import "./index.css";


const PrivacyPolicy = () => {
    return (
        <main className='main main--gray' id='privacyPolicy'>
            <div class="privacy-header">
                <h1>Aviso de Privacidad</h1>
            </div>
            <section className="privacy-content">
                <p>
                    En cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares en adelante LFPDPPP y su
                    Reglamento, se emite el presente Aviso de Privacidad que cumple con los requisitos legales señalados en el artículo 16 de dicha Ley, por parte de
                    <strong > OPCIONES DE SEGURIDAD INFORMÁTICA, S.A.P.I. DE C.V., en adelante TESSERACT,</strong>  quien tiene su domicilio en Av. Ejército Nacional Mexicano 453, 
                    Polanco IV Secc, Miguel Hidalgo, 11520 Ciudad de México, CDMX toda vez que <strong>TESSERACT</strong> es responsable de
                    velar por el cumplimiento de los principios de protección de datos personales establecidos en la LFPDPPP.
                </p>
                <p>
                    La <strong>FINALIDAD DEL TRATAMIENTO DE LOS DATOS</strong> solicitados y recabados
                    actualmente o en futuro que obren en nuestras bases de datos, de nuestros clientes,
                    radica en lo siguiente:
                </p>
                <p>
                    <ul className="privacy-list">
                        <li>Nombres.</li>
                        <li>Apellidos.</li>
                        <li>Registro Federal de Contribuyentes (RFC).</li>
                        <li>Número de teléfono.</li>
                        <li>Correo electrónico.</li>
                        <li>Ubicación, exacta o aproximada.</li>
                        <li>Nombre y versión del Sistema Operativo.</li>
                        <li>Medios de consumo: lenguajes de programación, aplicaciones y versiones para consumo de servicios</li>
                        <li>Números de vinculación para dispositivos móviles, dirección MAC, IMEI, Modelo, número de serie, Marca del dispositivo.</li>
                        <li>Dirección IP.</li>
                        <li>Identificación oficial y sus datos.</li>
                        <li>Comprobante de domicilio y sus datos.</li>
                        <li>Fotografía y/o video selfie.</li>
                        <li>Firma Manuscrita.</li>
                        <li>E-firma.</li>
                    </ul>
                </p>
                <p>
                    <ul className="privacy-list2">
                        <li>Para efecto de proporcionar referencias a cualquiera de las empresas u organismos filiales, subsidiarias y/o relacionadas con TESSERACT nacionales o extranjeros para los mismos fines.</li>
                        <li>Enviarle y presentarle bienes y/o servicios que puedan resultar relevantes o atractivos, incluyendo su participación en promociones, eventos y campañas, propias o de terceros.</li>
                        <li>Informar sobre cambios que consideramos puedan ser de su interés.</li>
                        <li>Dar cumplimiento a obligaciones legales.</li>
                        <li>Realizar estudios internos sobre tendencias de mercado.</li>
                    </ul>
                </p>

                <p>
                    La plataforma de Administración de Tesseract (<a href="https://admin.tesseract.mx">https://admin.tesseract.mx</a>) por parte de <strong>TESSERACT</strong>, almacenará la información proporcionada por los usuarios, la cual es protegida utilizando llaves de cifrado AES 256.
                </p>
                <p>
                    A su vez, la aplicación de Tesseract Authenticator por parte de <strong>TESSERACT</strong>, requiere del acceso y uso de la cámara del dispositivo con el propósito de lectura de código QR.
                </p>
                <p>
                    De forma independiente, el sitio web <a href="https://tesseract.mx">tesseract.mx</a> propiedad de <strong>TESSERACT</strong>,
                    almacena información de los visitantes a través de la interacción del chat bajo los
                    mismos parámetros anteriormente mencionados. Los Datos serán tratados de
                    conformidad con la LFPDPPP y su Reglamento. La confidencialidad de los Datos
                    está garantizada y los mismos están protegidos por medidas de seguridad
                    administrativas, técnicas y físicas, para evitar su daño, pérdida, alteración,
                    destrucción, uso, acceso o divulgación indebida. Únicamente las personas
                    autorizadas tendrán acceso a sus Datos.
                </p>
                <p>
                    Los responsables de recabar los datos personales serán los Sistemas de
                    Informáticos de <strong>TESSERACT</strong> incluyendo base de datos, CRM, aplicaciones móviles
                    y sistemas internos de la institución, mediante el llenado de formularios en la página
                    o aplicaciones móviles.
                </p>
                <p>
                    <strong>TESSERACT</strong> a través de sus Sistemas Informáticos será responsable del
                    tratamiento de los datos personales, por lo que está obligada a cumplir con los
                    principios de licitud, consentimiento, información, calidad, finalidad, lealtad,
                    proporcionalidad y responsabilidad tutelados en la LFPDPPP; por tal motivo con
                    fundamento en los artículos 13 y 14 de la LFPDPPP, <strong>TESSERACT</strong>, se compromete
                    a guardar estricta confidencialidad de los datos personales, así como a mantener
                    las medidas de seguridad administrativas, técnicas y físicas que permitan
                    protegerlos contra cualquier daño, pérdida, alteración, acceso o tratamiento no
                    autorizado.
                </p>
                <p>
                    En términos de lo establecido por el artículo 22 de la LFPDPPP, tienes derecho en
                    cualquier momento a ejercer los derechos de acceso, rectificación, cancelación y
                    oposición al tratamiento de los datos personales, mediante la solicitud vía correo
                    electrónico dirigido a Contacto <strong>TESSERACT</strong>, <a>contacto@tesseract.mx</a>. o por escrito
                    en el domicilio de TESSERACT, por lo que la solicitud debe contener los requisitos
                    que marca la LFPDPPP.
                </p>
                <p>
                    <strong>TESSERACT</strong> se reserva el derecho de cambiar, modificar, complementar y/o alterar
                    el presente aviso, en cualquier momento. En caso de existir alguna modificación al
                    presente Aviso de Privacidad se hará de su conocimiento en nuestro sitio de Internet &nbsp;
                    <a href="https://tesseract.mx">tesseract.mx</a>.
                </p>
                <p>
                    Reconozco que he leído y entendido el alcance y significado del presente aviso de
                    privacidad, por lo que manifiesto mi consentimiento, mediante el uso de la página
                    web de <strong>TESSERACT</strong> y aplicación.
                </p>
            </section>
        </main>
    )
}

export default PrivacyPolicy
