import './developers.css';
import { useLayoutEffect } from 'react';
import Image from '../../images/t-icon.svg'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'simplelightbox/dist/simple-lightbox.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  faInfoCircle, faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'

function Developers() {
  var get = function (selector, scope) {
    scope = scope ? scope : document;
    return scope.querySelector(selector);
  };

  var getAll = function (selector, scope) {
    scope = scope ? scope : document;
    return scope.querySelectorAll(selector);
  };
  useLayoutEffect(() => {
    //in page scrolling for documentaiton page
    var btns = getAll('.nav-link');

    function setActiveLink(event) {
      // remove all active tab classes
      for (var i = 0; i < btns.length; i++) {
        btns[i].classList.remove('selected');
      }

      event.target.classList.add('selected');
    }

    function smoothScrollTo(i, event) {
      setActiveLink(event);
    }

    if (btns.length > 0) {
      for (var i = 0; i < btns.length; i++) {
        btns[i].addEventListener('click', smoothScrollTo.bind(this, i));
      }
    }
    if (document.readyState === "complete") {
      // get all tab_containers in the document
      var tabContainers = getAll(".tab__container");

      // bind click event to each tab container
      for (i = 0; i < tabContainers.length; i++) {
        get('.tab__menu', tabContainers[i]).addEventListener("click", tabClick);
      }

      // each click event is scoped to the tab_container
      function tabClick(event) {
        var scope = event.currentTarget.parentNode;
        var clickedTab = event.target;
        var tabs = getAll('.tab', scope);
        var panes = getAll('.tab__pane', scope);
        var activePane = get(`.${clickedTab.getAttribute('data-tab')}`, scope);

        // remove all active tab classes
        for (var i = 0; i < tabs.length; i++) {
          tabs[i].classList.remove('active');
        }

        // remove all active pane classes
        for (i = 0; i < panes.length; i++) {
          panes[i].classList.remove('active');
        }

        // apply active classes on desired tab and pane
        clickedTab.classList.add('active');
        activePane.classList.add('active');
      }

    } else {

      //in page scrolling for documentaiton page
      btns = getAll('.nav-link');

      function setActiveLink(event) {
        // remove all active tab classes
        for (var i = 0; i < btns.length; i++) {
          btns[i].classList.remove('selected');
        }

        event.target.classList.add('selected');
      }

      function smoothScrollTo(i, event) {
        setActiveLink(event);
      }

      if (btns.length > 0) {
        for (i = 0; i < btns.length; i++) {
          btns[i].addEventListener('click', smoothScrollTo.bind(this, i));
        }
      }

      // toggle tabs on codeblock
      window.addEventListener("load", function () {
        console.log("load")
        // get all tab_containers in the document
        var tabContainers = getAll(".tab__container");

        // bind click event to each tab container
        for (var i = 0; i < tabContainers.length; i++) {
          get('.tab__menu', tabContainers[i]).addEventListener("click", tabClick);
        }

        // each click event is scoped to the tab_container
        function tabClick(event) {
          var scope = event.currentTarget.parentNode;
          var clickedTab = event.target;
          var tabs = getAll('.tab', scope);
          var panes = getAll('.tab__pane', scope);
          var activePane = get(`.${clickedTab.getAttribute('data-tab')}`, scope);

          // remove all active tab classes
          for (var i = 0; i < tabs.length; i++) {
            tabs[i].classList.remove('active');
          }

          // remove all active pane classes
          for (i = 0; i < panes.length; i++) {
            panes[i].classList.remove('active');
          }

          // apply active classes on desired tab and pane
          clickedTab.classList.add('active');
          activePane.classList.add('active');
        }
      });
    }
  }, [])
  return (
    <div className="App">
      <div className="page-wrapper">
        <header id="header" className="header">
          <div className="container">
            <div className="branding">
              <h1 className="logo">
                <a href="/">
                  <img className="icon" src={Image} alt="tesseract" />
                  <span className="text-highlight"> Tesseract </span><span className="text-bold">Crypto API</span>
                </a>
              </h1>

            </div>
          </div>
        </header>
        <div className="doc-wrapper">
          <div className="container">
            <div id="doc-header" className="doc-header">
              <div className="meta"><i className="far fa-clock"></i> Ultima actualización: Marzo 10, 2023</div>
            </div>
            <div className="doc-body row">
              <div className="doc-content col-md-9 col-12 order-1">
                <div className="content-inner">
                  <section id="intro-section" className="doc-section">
                    <h2 className="section-title">Introducción</h2>
                    <div className="section-block">
                      <p>Tesseract API provee la capacidad para que sus aplicaciones puedan implementar cífrado seguro mediante HSM el cual es un procesador criptográfico diseñado específicamente para la protección del ciclo de vida de las claves criptográficas.</p>
                      <p>Tesseract utiliza los Hardware Security Modules (HSMs) de Gemalto. Si desea saber más acerca de los módulos de seguridad de gemalto puede consultar la documentación aquí.</p>
                    </div>
                    <div className="section-block">
                      <div className="callout-block callout-info">
                        <div className="icon-holder">
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </div>
                        <div className="content">
                          <p>Le invitamos a probar <strong>Tesseract API</strong>, sin embargo, tenga en cuenta que se requiere un contrato de servicio firmado entre
                            usted y <strong>Tesseract</strong> para el uso comercial del API. Póngase en contacto con nosotros en
                            <span style={{ color: "#47b154" }}><a href="mailto:soporte@tesseract.mx"> soporte@tesseract.mx</a></span> para
                            obtener más información.</p>

                        </div>
                      </div>
                    </div>
                    <div className="section-block">
                      <p>Todo acceso a la API se realiza a través de peticiones <strong>HTTPS</strong>, accediendo desde el
                        siguiente enlace: <code>https://sandbox.tesseract.mx/api/v2</code> </p>
                      <p>El <strong>Tesseract API</strong> le permite administrar sus Objetos Criptograficos, licencias y tokens
                        en la nube de forma transparente, mediante el uso de peticiones <strong>HTTPS</strong> utilizadas de la
                        siguiente forma:</p>

                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Método</th>
                              <th>Seguro</th>
                              <th>Idempotencia</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>GET</td>
                              <td>SI</td>
                              <td>SI</td>
                              <td>Lee un recurso</td>
                            </tr>
                            <tr>
                              <td>POST</td>
                              <td>NO</td>
                              <td>NO</td>
                              <td>Crea un recurso</td>
                            </tr>
                            <tr>
                              <td>PUT</td>
                              <td>NO</td>
                              <td>SI</td>
                              <td>Actualiza un recurso</td>
                            </tr>
                            <tr>
                              <td>DELETE</td>
                              <td>NO</td>
                              <td>SI</td>
                              <td>Elimina un recurso</td>
                            </tr>
                          </tbody>
                        </table>

                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Método</th>
                              <th>Uso</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>GET</td>
                              <td>Recuperación de información sobre su cuenta. La información que
                                solicite le devolverá un objeto <code>JSON</code> o <code>XML</code>. Cualquier solicitud
                                que utilice el método <strong>GET</strong> es de solo lectura y no afectará a ninguno de los
                                objetos que está consultando.</td>
                            </tr>
                            <tr>
                              <td>POST</td>
                              <td>Creación de un nuevo objeto, su solicitud debe especificar el método
                                <strong> POST</strong>. La solicitud <strong> POST</strong> incluye todos los atributos
                                necesarios para crear un nuevo objeto.</td>
                            </tr>
                            <tr>
                              <td>PUT</td>
                              <td>Actualización de la información sobre un recurso en su cuenta. El
                                método <strong>PUT</strong> establece el estado del objetivo utilizando los valores
                                proporcionados, independientemente de sus valores actuales.</td>
                            </tr>
                            <tr>
                              <td>DELETE</td>
                              <td>Eliminación de un objeto especificado si se encuentra. Si no se
                                encuentra, la operación devolverá una respuesta que indica que el objeto no se encontró.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <p>La documentación de <strong>Tesseract API</strong> inicia con la visión general sobre el diseño y la
                        tecnología que se ha descrito anteriormente. </p>
                    </div>
                    <div id="request" className="section-block">
                      <h3 className="block-title">Request</h3>
                      <p><strong>Tesseract API</strong> tiene soporte para recibir su solicitud <em>HTTP y HTTPS</em> en formato
                        <code>JSON</code> o <code>XML</code>, como se muestra en los siguientes ejemplos:</p>
                      <div id="css" className="section-block">
                        <div className="tab__container">
                          <h6>Ejemplo</h6>
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="xml">xml</li>
                            <li className="tab" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active xml" language='curl' style={tomorrowNight}>{`curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution \\
  -H 'Accept: application/xml' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\
  -H 'Content-Type: application/xml' \\`}</SyntaxHighlighter>
                          <SyntaxHighlighter className="tab__pane json" language='curl' style={tomorrowNight}>{`curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\
  -H 'Content-Type: application/json' \\`}</SyntaxHighlighter>

                        </div>

                      </div>
                      <div id="css" className="section-block">
                        <div className="tab__container">
                          <h6>Encabezados de la respuesta</h6>
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="xml">xml</li>
                            <li className="tab" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active xml" language='curl' style={tomorrowNight}>{`Status 200 200
  Content-Type: application/xml;charset=UTF-8`}</SyntaxHighlighter>
                          <SyntaxHighlighter className="tab__pane json" language='curl' style={tomorrowNight}>{`Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>

                        </div>

                      </div>
                    </div>

                    <div id="response" className="section-block">
                      <h3 className="block-title">Response</h3>
                      <p>Las solicitudes se deben realizar utilizando peticiones en formato <strong>JSON</strong> o
                        <strong>XML</strong>. La interfaz responde a diferentes métodos según la acción requerida.</p>
                      <p>El <code>RESPONSE</code> puede regresar objetos de tipo <code>JSON</code> o  <code> XML</code> como se
                        muestra a continuación:</p>
                      <div id="css" className="section-block">
                        <div className="tab__container">
                          <h6>Encabezados de la respuesta</h6>
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="xml">xml</li>
                            <li className="tab" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active xml" language='xml' style={tomorrowNight}>{`  <institutions>
  <id>1</id>
  <common_name>tesseract.mx</common_name>
  <organization>Opciones en Seguridad Informática S.A.P.I. de C.V.</organization>
  <organization_unit>Tesseract MX</organization_unit>
  <country>MX</country>
  <state>Ciudad de México</state>
  <locality>Cuauhtemoc</locality>
  <email>jahaziel.retiz@tesseract.mx</email>
  <token_label>HA_TESSERACT</token_label>
  <created_at>2019-04-05T15:52:19.000+0000</created_at>
  <updated_at>2019-04-05T15:52:19.000+0000</updated_at>
  <links>
      <links>
          <rel>self</rel>
          <href>https://sandbox.tesseract.mx/api/v2/institution</href>
          <hreflang/>
          <media/>
          <title/>
          <type/>
          <deprecation/>
      </links>
      <links>
          <rel>apps</rel>
          <href>https://sandbox.tesseract.mx/api/v2/institution/apps</href>
          <hreflang/>
          <media/>
          <title/>
          <type/>
          <deprecation/>
      </links>
      <links>
          <rel>licenses</rel>
          <href>https://sandbox.tesseract.mx/api/v2/institution/licenses</href>
          <hreflang/>
          <media/>
          <title/>
          <type/>
          <deprecation/>
      </links>
      <links>
          <rel>partition</rel>
          <href>https://sandbox.tesseract.mx/api/v2/institution/partition</href>
          <hreflang/>
          <media/>
          <title/>
          <type/>
          <deprecation/>
      </links>
  </links>`}</SyntaxHighlighter>
                          <SyntaxHighlighter className="tab__pane json" language='json' style={tomorrowNight}>{`  {
    "id": 1,
    "common_name": "tesseract.mx",
    "organization": "Opciones en Seguridad Informática S.A.P.I. de C.V.",
    "organization_unit": "Tesseract MX",
    "country": "MX",
    "state": "Ciudad de México",
    "locality": "Cuauhtemoc",
    "email": "jahaziel.retiz@tesseract.mx",
    "token_label": "HA_TESSERACT",
    "created_at": "2019-04-05T15:52:19.000+0000",
    "updated_at": "2019-04-05T15:52:19.000+0000",
    "_links": {
        "self": {
            "href": "https://sandbox.tesseract.mx/api/v2/institution"
        },
        "apps": {
            "href": "https://sandbox.tesseract.mx/api/v2/institution/apps"
        },
        "licenses": {
            "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses"
        },
        "partition": {
            "href": "https://sandbox.tesseract.mx/api/v2/institution/partition"
        }
    }
  }`}</SyntaxHighlighter>

                        </div>

                      </div>
                    </div>

                    <div id="links" className="section-block">
                      <h3 className="block-title">Links</h3>
                      <p>Además del contenido principal de los recursos, la respuesta contiene al objeto <code>_links</code>.
                        Dicho objeto contiene información sobre paginación en el caso de las colecciones y recursos asociados al
                        recurso individual solicitado anteriormente.</p>

                    </div>
                    <div id="resource_links" className="section-block">
                      <h3 className="block-title">Links de recurso</h3>
                      <p>Al tratarse de un recurso individual el objeto <code>_links</code> contiene información hacia recursos
                        relacionados y hacia la localización del recurso actual, a continuación, se describe un ejemplo: </p>

                      <div id="css" className="section-block">
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`  "_links": {
      "self": {
          "href": "https://sandbox.tesseract.mx/api/v2/institution"
      },
      "apps": {
          "href": "https://sandbox.tesseract.mx/api/v2/institution/apps"
      },
      "licenses": {
          "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses"
      },
      "partition": {
          "href": "https://sandbox.tesseract.mx/api/v2/institution/partition"
      }
  }`}</SyntaxHighlighter>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Nombre</th>
                                <th>Tipo</th>
                                <th>Descripción</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><strong>self</strong></td>
                                <td>object</td>
                                <td>Link al recurso actual</td>
                              </tr>
                              <tr>
                                <td><strong>apps</strong></td>
                                <td>object</td>
                                <td>Link al recurso aplicaciones</td>
                              </tr>
                              <tr>
                                <td><strong>licenses</strong></td>
                                <td>object</td>
                                <td>Link al recurso licencias</td>
                              </tr>
                              <tr>
                                <td><strong>partition</strong></td>
                                <td>object</td>
                                <td>Link al recurso particiones</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div id="metainformation" className="section-block">
                      <h3 className="block-title">Metainformación</h3>
                      <p>Al tratarse de un recurso individual el objeto <code>_links</code> contiene información hacia recursos
                        relacionados y hacia la localización del recurso actual, a continuación, se describe un ejemplo: </p>

                      <div id="css" className="section-block">
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`"_page" : {
      "size": 15,
      "total_elements": 1,
      "total_pages": 1,
      "number": 0
  }`}</SyntaxHighlighter>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Nombre</th>
                                <th>Tipo</th>
                                <th>Descripción</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><strong>size</strong></td>
                                <td>numérico</td>
                                <td>Elementos solicitados actualmente</td>
                              </tr>
                              <tr>
                                <td><strong>total_elements</strong></td>
                                <td>numérico</td>
                                <td>Total de elementos encontrados</td>
                              </tr>
                              <tr>
                                <td><strong>total_pages</strong></td>
                                <td>numérico</td>
                                <td>Total de páginas de elementos</td>
                              </tr>
                              <tr>
                                <td><strong>number</strong></td>
                                <td>numérico</td>
                                <td>Página actual (se inicia en la página 0)</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </section>
                  <section id="httpstates-section" className="doc-section">
                    <h2 className="section-title">Estados HTTP</h2>
                    <p>El API indicara por medio de estados HTTP el estado de la solicitud <code>Request</code> a continuación
                      la descripción de estos códigos:</p>
                    <div id="satisfactory" className="section-block">
                      <h3 className="block-title">Satisfactorios</h3>
                      <p>Casos en los que es satisfactoria la solicitud realizada.</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Respuesta</th>
                              <th>Código de respuesta HTTP</th>
                              <th>Significado</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>Ok</strong></td>
                              <td>200</td>
                              <td>Respuesta estándar para peticiones correctas.</td>
                            </tr>
                            <tr>
                              <td><strong>Created</strong></td>
                              <td>201</td>
                              <td>Respuesta generada después de usar la petición <strong>POST</strong>, únicamente al crear nuevos recursos. </td>
                            </tr>
                            <tr>
                              <td><strong>No Content</strong></td>
                              <td>204</td>
                              <td>La petición se ha completado con éxito pero su respuesta no tiene
                                ningún contenido.</td>
                            </tr>
                            <tr>
                              <td><strong>Reset Content</strong></td>
                              <td>205</td>
                              <td>La petición se ha completado con éxito, pero su respuesta no tiene
                                contenidos y además, el navegador tiene que inicializar la página desde la que se realizó la
                                petición (este código es útil por ejemplo para páginas con formularios cuyo contenido debe
                                borrarse después de que el usuario lo envíe).</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="errors" className="section-block">
                      <h3 className="block-title">Errores</h3>
                      <p>Si se produce un error, la API responderá con el código de estado <strong>HTTP</strong> apropiado y un
                        objeto de mensaje de error que contiene un codigo de error: <code>code</code> y es legible desde el
                        punto de vista humano: <code>message</code>.</p>
                      <p>Lista de códigos de error: </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre del error</th>
                              <th>Código de estado HTTP</th>
                              <th>Significado</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>Bad Request</strong></td>
                              <td>400</td>
                              <td>La solicitud contiene sintaxis errónea.</td>
                            </tr>
                            <tr>
                              <td><strong>Unauthorized</strong></td>
                              <td>401</td>
                              <td>La autentificación es posible pero ha fallado o aún no ha sido
                                provista.</td>
                            </tr>
                            <tr>
                              <td><strong>Forbidden</strong></td>
                              <td>403</td>
                              <td>El cliente no tiene los privilegios para hacerla.</td>
                            </tr>
                            <tr>
                              <td><strong>Not Found</strong></td>
                              <td>404</td>
                              <td>El servidor web no encuentra la página o recurso solicitado.</td>
                            </tr>
                            <tr>
                              <td><strong>Method Not Allowed</strong></td>
                              <td>405</td>
                              <td>Una petición fue hecha a una URI utilizando un método de solicitud
                                no soportado por dicha URI; por ejemplo, cuando se utiliza <strong>GET</strong> en un
                                formulario que requiere que los datos sean presentados vía <strong>POST</strong>, o
                                utilizando <strong>PUT</strong> en un recurso de solo lectura. </td>
                            </tr>
                            <tr>
                              <td><strong>Not Acceptable</strong></td>
                              <td>406</td>
                              <td>El servidor no es capaz de devolver los datos en ninguno de los
                                formatos aceptados por el cliente.</td>
                            </tr>
                            <tr>
                              <td><strong>Gone</strong></td>
                              <td>410</td>
                              <td>Indica que el recurso solicitado ya no está disponible y no lo
                                estará de nuevo.</td>
                            </tr>
                            <tr>
                              <td><strong>Too Many Request</strong></td>
                              <td>429</td>
                              <td>El <strong>Rate Limit</strong> fue exedido, por tanto tiene que
                                esperar.</td>
                            </tr>
                            <tr>
                              <td><strong>Internal Server Error</strong></td>
                              <td>500</td>
                              <td>Situaciones de error ajenas a la naturaleza del servidor web.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  <section id="auth-section" className="doc-section">
                    <h2 className="section-title">Autenticación</h2>
                    <p>Las acciones descritas a continuación se realizarán desde el <strong>Tesseract Admin</strong> y deben
                      llevarse a cabo antes de comenzar a utilizar el <strong>Tesseract API</strong>.</p>
                    <div id="registration" className="section-block">
                      <h3 className="block-title">Registro</h3>
                      <p>Para el uso de Soft Token de <strong>Tesseract API</strong>, primero se debe registrar una aplicación,
                        una vez hecho esto, se le asignará un ID único y una partición dentro del <em>HSM</em>.</p>
                    </div>
                    <div id="stub" className="section-block">
                      <h3 className="block-title">Resguardo</h3>
                      <p>Finalizado el registro de la aplicación, se recibirán las credenciales por única ocasión, se recomienda
                        el resguardo inmediato ya que <strong>Tesseract</strong> no será responsable por el uso de estas después
                        de ser entregadas.<br />Las credenciales entregadas a la hora de realizar el registro se identificarán de
                        la siguiente manera:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>Access Key ID</strong></td>
                              <td>Llave Única de Acceso que realizará las peticiones (compuesta por 20
                                caracteres)</td>
                            </tr>
                            <tr>
                              <td><strong>Secret Access Key</strong></td>
                              <td>Contraseña con el que se realizará el proceso de autenticación
                                (compuesta por 40 caracteres)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="activation" className="section-block">
                      <h3 className="block-title">Activación</h3>
                      <p>Es necesario recalcar que una vez registrada la aplicación se encuentra desactivada, se debe realizar la
                        activación de la aplicación para comenzar con el uso del API.</p>
                      <div className="callout-block callout-danger">
                        <div className="icon-holder">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </div>
                        <div className="content">
                          <p>Para interactuar con Tesseract API, tu aplicación debe autenticarse.</p>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section id="authr-section" className="doc-section">
                    <div className="section-block">
                      <h2 className="section-title">Auth</h2>
                      <p>Puede realizar una petición de tipo <code>GET</code>a la siguiente URL
                        <code>https://sandbox.tesseract.mx/api/v2/auth</code> para obtener la información correspondiente a la
                        aplicación que se ha autenticado, en donde se recupera la siguiente información:</p>
                      <h6>EJEMPLO</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/auth \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='php' style={tomorrowNight}>{`<?php

require 'vendor/autoload.php';

use Tesseract\\Crypto\\SDK\\CryptoSDK;
use Tesseract\\Crypto\\SDK\\Http\\StatusCode;
use Tesseract\\Crypto\\SDK\\Options\\Config;
use Tesseract\\Crypto\\SDK\\Options\\HttpClientConfig;

$configs = include('config.php'); // array

$httpClientConfig = new HttpClientConfig($configs[Config::BASE_URL],
    $configs[Config::ACCESS_KEY_ID],
    $configs[Config::SECRET_ACCESS_KEY],
    $configs[Config::DEBUG], // optional
    $configs[Config::TIMEOUT]); // optional

$sdk = new CryptoSDK($httpClientConfig);

$response = $sdk->auth();

if($response->getStatusCode() == StatusCode::OK)
{
    if($response->getBody()->isReadable())
        echo $response->getBody()->getContents();
}`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <div className="section-block">
                      <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  GET /api/v2/auth HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='curl' style={tomorrowNight}>{`  GET /api/v2/auth HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                      </div></div>
                    <div className="section-block">
                      <h6>ENCABEZADO DE LA RESPUESTA</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='curl' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <div className="section-block">
                      <h6>CUERPO DE LA RESPUESTA</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="json">json</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`    {
      "id": 11,
      "name": "Documentación",
      "enabled": true,
      "access_key_id": null,
      "secret_access_key": null,
      "roles": [
          "LICENSE_ROLE",
          "APP_ROLE",
          "PARTITION_ROLE",
          "RSA_ROLE",
          "AES_ROLE",
          "KEY_ROLE",
          "AUTH_ROLE",
          "DES_ROLE",
          "CERTIFICATE_ROLE",
          "HASH_ROLE",
          "INSTITUTION_ROLE",
          "TOKEN_ROLE"
      ],
      "created_at": "2018-06-19T00:14:06.000+0000",
      "updated_at": "2018-06-19T00:14:06.000+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/apps/11"
          }
      }
  }
`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='json' style={tomorrowNight}>{`    {
      "id": 11,
      "name": "Documentación",
      "enabled": true,
      "access_key_id": null,
      "secret_access_key": null,
      "roles": [
          "LICENSE_ROLE",
          "APP_ROLE",
          "PARTITION_ROLE",
          "RSA_ROLE",
          "AES_ROLE",
          "KEY_ROLE",
          "AUTH_ROLE",
          "DES_ROLE",
          "CERTIFICATE_ROLE",
          "HASH_ROLE",
          "INSTITUTION_ROLE",
          "TOKEN_ROLE"
      ],
      "created_at": "2018-06-19T00:14:06.000+0000",
      "updated_at": "2018-06-19T00:14:06.000+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/apps/11"
          }
      }
  }
`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Tipo</th>
                            <th>Descripción </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><strong>id</strong></td>
                            <td>number</td>
                            <td>Identificador único de la aplicación.</td>
                          </tr>
                          <tr>
                            <td><strong>name</strong></td>
                            <td>string</td>
                            <td>Etiqueta de nombre para identificar una aplicación.</td>
                          </tr>
                          <tr>
                            <td><strong>enabled</strong></td>
                            <td>boolean</td>
                            <td>Boolean el cual indica <code>false</code> si la aplicación esta desactivada y
                              <code>true</code> si la aplicación se encuentra activa.</td>
                          </tr>
                          <tr>
                            <td><strong>access_key_id</strong></td>
                            <td>string</td>
                            <td>Llave única de acceso con la cual se realizarán las peticiones (véase Resguardo).
                              <strong>Por seguridad este valor siempre será null.</strong></td>
                          </tr>
                          <tr>
                            <td><strong>secret_access_key</strong></td>
                            <td>string</td>
                            <td>Contraseña del usuario con la cual se realizarán las peticiones (véase Resguardo).
                              <strong>Por seguridad este valor siempre será null.</strong></td>
                          </tr>

                          <tr>
                            <td><strong>roles</strong></td>
                            <td>array</td>
                            <td>Indica todos los roles los cuales posee la aplicación.</td>
                          </tr>
                          <tr>
                            <td><strong>created_at</strong></td>
                            <td>string</td>
                            <td>Representación de la fecha de creación de la aplicación en la zona horaria UTC.</td>
                          </tr>
                          <tr>
                            <td><strong>updated_at</strong></td>
                            <td>string</td>
                            <td>Representación de la ultima fecha de actualización de la aplicación en la zona horaria UTC.
                            </td>
                          </tr>
                          <tr>
                            <td><strong>_links</strong></td>
                            <td>object</td>
                            <td>Contiene los links a recursos asociados.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                  <section id="institution-section" className="doc-section">
                    <div className="section-block">
                      <h2 className="section-title">Institución</h2>
                      <p>Puede realizar una petición de tipo <code>GET</code> a
                        <code>https://sandbox.tesseract.mx/api/v2/institution</code> para observar la información de la
                        institución correspondiente.</p>

                      <h6>EJEMPLO</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`    curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='php' style={tomorrowNight}>{`<?php

require 'vendor/autoload.php';

use Tesseract\\Crypto\\SDK\\CryptoSDK;
use Tesseract\\Crypto\\SDK\\Http\\StatusCode;
use Tesseract\\Crypto\\SDK\\Options\\Config;
use Tesseract\\Crypto\\SDK\\Options\\HttpClientConfig;

$configs = include('config.php'); // array

$httpClientConfig = new HttpClientConfig($configs[Config::BASE_URL],
    $configs[Config::ACCESS_KEY_ID],
    $configs[Config::SECRET_ACCESS_KEY],
    $configs[Config::DEBUG], // optional
    $configs[Config::TIMEOUT]); // optional

$sdk = new CryptoSDK($httpClientConfig);

$response = $sdk->institution();

if($response->getStatusCode() == StatusCode::OK)
{
    if($response->getBody()->isReadable())
        echo $response->getBody()->getContents();
}`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <div className="section-block">
                      <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  GET /api/v2/institution HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='curl' style={tomorrowNight}>{`  GET /api/v2/institution HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                      </div></div>
                    <div className="section-block">
                      <h6>ENCABEZADO DE LA RESPUESTA</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='curl' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <div className="section-block">
                      <h6>CUERPO DE LA RESPUESTA</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="json">json</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`    {
      "id": 1,
      "common_name": "tesseract.mx",
      "organization": "Opciones en Seguridad Informática S.A.P.I. de C.V.",
      "organization_unit": "Tesseract MX",
      "country": "MX",
      "state": "Ciudad de México",
      "locality": "Cuauhtemoc",
      "email": "jahaziel.retiz@tesseract.mx",
      "token_label": "HA_TESSERACT",
      "created_at": "2019-04-05T15:52:19.000+0000",
      "updated_at": "2019-04-05T15:52:19.000+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution"
          },
          "apps": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/apps"
          },
          "licenses": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses"
          },
          "partition": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/partition"
          }
      }
  }`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='json' style={tomorrowNight}>{`    {
      "id": 1,
      "common_name": "tesseract.mx",
      "organization": "Opciones en Seguridad Informática S.A.P.I. de C.V.",
      "organization_unit": "Tesseract MX",
      "country": "MX",
      "state": "Ciudad de México",
      "locality": "Cuauhtemoc",
      "email": "jahaziel.retiz@tesseract.mx",
      "token_label": "HA_TESSERACT",
      "created_at": "2019-04-05T15:52:19.000+0000",
      "updated_at": "2019-04-05T15:52:19.000+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution"
          },
          "apps": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/apps"
          },
          "licenses": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses"
          },
          "partition": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/partition"
          }
      }
  }`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <p>A continuación, se muestra la descripción del <code>RESPONSE</code> del recurso recuperado.</p>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Tipo</th>
                            <th>Descripción </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><strong>id</strong></td>
                            <td>number</td>
                            <td>Identificador único para la institución</td>
                          </tr>
                          <tr>
                            <td><strong>common_name</strong></td>
                            <td>string</td>
                            <td>El Nombre común (también CN) identifica el nombre de host asociado con el certificado, por
                              ejemplo <a href="http://www.example.com">www.example.com</a> como example.com.</td>
                          </tr>
                          <tr>
                            <td><strong>organization</strong></td>
                            <td>string</td>
                            <td>Nombre de la organización: el nombre legal exacto de su organización. Ejemplo:
                              “SpreeCommerce, Inc.” Si no tiene un nombre de organización legalmente registrado, debe
                              ingresar su nombre completo aquí.</td>
                          </tr>
                          <tr>
                            <td><strong>organization_unit</strong></td>
                            <td>string</td>
                            <td>Departamento: muchas personas dejan este campo en blanco. Este es el departamento dentro de
                              su organización que desea que aparezca en el certificado. Se enumerará en el tema del
                              certificado como Unidad organizativa o “ou”. Ejemplo: Administración web, Seguridad web,
                              Marketing.</td>
                          </tr>
                          <tr>
                            <td><strong>country</strong></td>
                            <td>string</td>
                            <td>País: el condado donde está ubicada legalmente su organización.</td>
                          </tr>
                          <tr>
                            <td><strong>state</strong></td>
                            <td>string</td>
                            <td>Estado o provincia: el estado o provincia donde su organización se encuentra legalmente.
                            </td>
                          </tr>
                          <tr>
                            <td><strong>locality</strong></td>
                            <td>string</td>
                            <td>Ubicación / Ciudad: la ciudad donde está ubicada legalmente su organización.</td>
                          </tr>
                          <tr>
                            <td><strong>email</strong></td>
                            <td>string</td>
                            <td>Correo electrónico del custodio de la partición</td>
                          </tr>
                          <tr>
                            <td><strong>token_label</strong></td>
                            <td>string</td>
                            <td>Partición del HSM asiganda</td>
                          </tr>
                          <tr>
                            <td><strong>created_at</strong></td>
                            <td>number</td>
                            <td>Representación de la fecha de creación de la institución en formato ISO 8601 a partir de
                              1970-01-01.</td>
                          </tr>
                          <tr>
                            <td><strong>updated_at</strong></td>
                            <td>number</td>
                            <td>Representación de la ultima fecha de actualización de la institución en formato ISO 8601 a
                              partir de 1970-01-01.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                  <section id="license-section" className="doc-section">
                    <div className="section-block">
                      <h2 className="section-title">Licencias</h2>
                      <p>Puede realizar una petición de tipo <code>GET</code> a
                        <code>https://sandbox.tesseract.mx/api/v2/institution/licenses</code>, para recuperar todas las
                        licencias de la institución correspondiente.</p>
                      <p>Por defecto recuperará los primeros <em>20 elementos (máximo 30)</em>, algunos ejemplos:</p>
                      <ul>
                        <li>
                          <p>Primeros 30
                            elementos:<br /><code>https://sandbox.tesseract.mx/api/v2/institution/licenses?size=30</code></p>
                        </li>
                        <li>
                          <p>Siguientes 30
                            elementos:<br /><code>https://sandbox.tesseract.mx/api/v2/institution/licenses?page=1&amp;size=30</code>
                          </p>
                        </li>
                        <li>
                          <p>Últimos 20
                            elementos:<br /><code>https://sandbox.tesseract.mx/api/v2/institution/licenses?page=2&amp;size=20</code>
                          </p>
                        </li>
                      </ul>
                      <h6>EJEMPLO</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses \\
  -H 'accept: application/json' \\
  -H 'authorization: Basic MHJ1R0hhaHk4cW1qdlFXbkw2cEI6YVJxakxweUs0VFZqS3JnMm9lWjdMTmZzVFFYRnZPN0Zrb3hIdkxRTg=='`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='php' style={tomorrowNight}>{`  <?php

require 'vendor/autoload.php';

use Tesseract\\Crypto\\SDK\\CryptoSDK;
use Tesseract\\Crypto\\SDK\\Http\\StatusCode;
use Tesseract\\Crypto\\SDK\\Options\\Config;
use Tesseract\\Crypto\\SDK\\Options\\HttpClientConfig;

$configs = include('config.php'); // array

$httpClientConfig = new HttpClientConfig($configs[Config::BASE_URL],
$configs[Config::ACCESS_KEY_ID],
$configs[Config::SECRET_ACCESS_KEY],
$configs[Config::DEBUG], // optional
$configs[Config::TIMEOUT]); // optional

$sdk = new CryptoSDK($httpClientConfig);

// $page = 0, $size = 20 default param values
$response = $sdk->licenses();

if($response->getStatusCode() == StatusCode::OK)
{
if($response->getBody()->isReadable())
    echo $response->getBody()->getContents();
}`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <div className="section-block">
                      <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='curl' style={tomorrowNight}>{`  curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                      </div></div>
                    <div className="section-block">
                      <h6>ENCABEZADO DE LA RESPUESTA</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='curl' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <div className="section-block">
                      <h6>CUERPO DE LA RESPUESTA</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="json">json</li>
                          <li className="tab" data-tab="php">php</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`{
      "_content": [
          {
              "id": 1,
              "stock": 10000,
              "free": 9890,
              "used": 110,
              "status": "ACTIVATED",
              "duration": 365,
              "activated_at": "2018-11-09T00:26:46.000+0000",
              "expired_at": "2019-11-09T00:26:46.000+0000",
              "created_at": "2018-11-09T00:26:11.000+0000",
              "updated_at": "2019-02-12T19:58:14.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1"
                  },
                  "tokens": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens"
                  }
              }
          }
      ],
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses?page=0&size=20"
          }
      },
      "_page": {
          "size": 20,
          "total_elements": 1,
          "total_pages": 1,
          "number": 0
      }
  }`}</SyntaxHighlighter>
                        <SyntaxHighlighter className="tab__pane php" language='json' style={tomorrowNight}>{`{
      "_content": [
          {
              "id": 1,
              "stock": 10000,
              "free": 9890,
              "used": 110,
              "status": "ACTIVATED",
              "duration": 365,
              "activated_at": "2018-11-09T00:26:46.000+0000",
              "expired_at": "2019-11-09T00:26:46.000+0000",
              "created_at": "2018-11-09T00:26:11.000+0000",
              "updated_at": "2019-02-12T19:58:14.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1"
                  },
                  "tokens": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens"
                  }
              }
          }
      ],
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses?page=0&size=20"
          }
      },
      "_page": {
          "size": 20,
          "total_elements": 1,
          "total_pages": 1,
          "number": 0
      }
  }`}</SyntaxHighlighter>
                      </div>
                      <p>A continuación se muestra la descripción del <code>RESPONSE</code> del recurso recuperado.</p>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Tipo</th>
                            <th>Descripción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><strong>_content</strong></td>
                            <td>array</td>
                            <td>Colección de objetos licencia.</td>
                          </tr>
                          <tr>
                            <td><strong>_links</strong></td>
                            <td>object</td>
                            <td>Colección de objetos link.</td>
                          </tr>
                          <tr>
                            <td><strong>_page</strong></td>
                            <td>object</td>
                            <td>Objeto con la metainformación de paginación.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div id="license" className="section-block">
                      <h3 className="block-title">Licencia</h3>
                      <p>Puede realizar una petición de tipo <code>GET</code> a <code>https://sandbox.tesseract.mx/api/v2/institution/licenses/{"{"}license_id{"}"}</code>
                        para recuperar una licencia especifica de la institución correspondiente, donde:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>license_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para licencia perteneciente a la institución</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                            <li className="tab active" data-tab="php">php</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses/1 \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                          <SyntaxHighlighter className="tab__pane php" language='php' style={tomorrowNight}>{`  <?php

require 'vendor/autoload.php';

use Tesseract\\Crypto\\SDK\\CryptoSDK;
use Tesseract\\Crypto\\SDK\\Http\\StatusCode;
use Tesseract\\Crypto\\SDK\\Options\\Config;
use Tesseract\\Crypto\\SDK\\Options\\HttpClientConfig;

$configs = include('config.php'); // array

$httpClientConfig = new HttpClientConfig($configs[Config::BASE_URL],
$configs[Config::ACCESS_KEY_ID],
$configs[Config::SECRET_ACCESS_KEY],
$configs[Config::DEBUG], // optional
$configs[Config::TIMEOUT]); // optional

$sdk = new CryptoSDK($httpClientConfig);

$licenseId = 1;

$response = $sdk->license($licenseId);

if($response->getStatusCode() == StatusCode::OK)
{
if($response->getBody()->isReadable())
    echo $response->getBody()->getContents();
}`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                            <li className="tab" data-tab="php">php</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`GET /api/v2/institution/licenses/1 HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                          <SyntaxHighlighter className="tab__pane php" language='php' style={tomorrowNight}>{`GET /api/v2/institution/licenses/1 HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>ENCABEZADO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                            <li className="tab" data-tab="php">php</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                          <SyntaxHighlighter className="tab__pane php" language='php' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                            <li className="tab" data-tab="php">php</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='json' style={tomorrowNight}>{`{
      "id": 1,
      "stock": 10000,
      "free": 9874,
      "used": 126,
      "status": "ACTIVATED",
      "duration": 1095,
      "activated_at": "2019-04-05T17:34:00.000+0000",
      "expired_at": "2022-04-04T17:34:00.000+0000",
      "created_at": "2019-04-05T17:32:56.000+0000",
      "updated_at": "2019-06-14T21:58:02.000+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1"
          },
          "tokens": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens"
          }
      }
  }`}</SyntaxHighlighter>
                          <SyntaxHighlighter className="tab__pane php" language='json' style={tomorrowNight}>{`{
      "id": 1,
      "stock": 10000,
      "free": 9874,
      "used": 126,
      "status": "ACTIVATED",
      "duration": 1095,
      "activated_at": "2019-04-05T17:34:00.000+0000",
      "expired_at": "2022-04-04T17:34:00.000+0000",
      "created_at": "2019-04-05T17:32:56.000+0000",
      "updated_at": "2019-06-14T21:58:02.000+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1"
          },
          "tokens": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens"
          }
      }
  }`}</SyntaxHighlighter>
                        </div>
                        <p>A continuación se muestra la descripción del <code>RESPONSE</code> del recurso recuperado.</p>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>id</strong></td>
                              <td>number</td>
                              <td>Identificador único de la licencia.</td>
                            </tr>
                            <tr>
                              <td><strong>stock</strong></td>
                              <td>number</td>
                              <td>Conjunto de tokens pertenecientes a la licencia.</td>
                            </tr>
                            <tr>
                              <td><strong>free</strong></td>
                              <td>number</td>
                              <td>Número de tokens libres del total perteneciente a la licencia.</td>
                            </tr>
                            <tr>
                              <td><strong>used</strong></td>
                              <td>number</td>
                              <td>Número de tokens usados del total perteneciente a la licencia.</td>
                            </tr>
                            <tr>
                              <td><strong>status</strong></td>
                              <td>string</td>
                              <td>Estado actual de la licencia.</td>
                            </tr>
                            <tr>
                              <td><strong>duration</strong></td>
                              <td>number</td>
                              <td>Número de días de validez de la licencia actual apartir de la fecha de activación.</td>
                            </tr>
                            <tr>
                              <td><strong>activated_at</strong></td>
                              <td>string</td>
                              <td>Representación de la fecha de activación de la licencia en la zona horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>expired_at</strong></td>
                              <td>string</td>
                              <td>Representación de la fecha de expiración de la licencia en la zona horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>created_at</strong></td>
                              <td>string</td>
                              <td>Representación de la fecha de creación de la licencia en la zona horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>updated_at</strong></td>
                              <td>string</td>
                              <td>Representación de la ultima fecha de actualización de la licencia en la zona horaria UTC.
                              </td>
                            </tr>
                            <tr>
                              <td><strong>_links</strong></td>
                              <td>object</td>
                              <td>Contiene los links a recursos asociados.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>

                  <section id="token-section" className="doc-section">
                    <div className="section-block">
                      <h2 className="section-title">Tokens</h2>
                      <p>Para recuperar todos los tokens de una licencia procedemos a realizar<br />una petición <code>GET</code> a
                        la siguiente
                        URL:<br /><code>https://sandbox.tesseract.mx/api/v2/institution/licenses/{"{"}license_id{"}"}/tokens</code>,</p>
                      <p>donde <strong>license_id</strong> es el identificador único de la licencia.</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>license_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para licencia perteneciente a la institución</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <h6>EJEMPLO</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`curl -X GET \
  https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens \
  -H 'Accept: application/json' \
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ=='`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <div className="section-block">
                      <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  GET /api/v2/institution/licenses/1/tokens HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                      </div></div>
                    <div className="section-block">
                      <h6>ENCABEZADO DE LA RESPUESTA</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="curl">curl</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                      </div>
                    </div>
                    <div className="section-block">
                      <h6>CUERPO DE LA RESPUESTA</h6>
                      <div className="tab__container">
                        <ul className="tab__menu">
                          <li className="tab active" data-tab="json">json</li>
                        </ul>
                        <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`    {
      "_content": [
          {
              "id": 1,
              "serial_no": "010100000001",
              "token_status": "BLOCKED",
              "token_type": "CHALLENGE_RESPONSE",
              "success_counter": 0,
              "failed_counter": 0,
              "algorithm_parameters": {
                  "suite": "SHA1",
                  "challenge_format": {
                      "encoding": "DECIMAL",
                      "min": 6,
                      "max": 6
                  },
                  "response_format": {
                      "encoding": "DECIMAL",
                      "length": 6
                  }
              },
              "data": {
                  "time": 0,
                  "time_interval": 120000
              },
              "pin_policy": {
                  "pin_usage_mode": "LOCAL",
                  "max_failed_attempts": 5,
                  "min_length": 4,
                  "max_length": 4,
                  "pin_encoding": "DECIMAL"
              },
              "created_at": "2019-04-06T00:12:49.000+0000",
              "updated_at": "2019-05-13T18:49:37.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/1"
                  }
              }
          },
          {
              "id": 2,
              "serial_no": "010100000002",
              "token_status": "WAITING",
              "token_type": "CHALLENGE_RESPONSE",
              "success_counter": 2,
              "failed_counter": 0,
              "algorithm_parameters": {
                  "suite": "SHA1",
                  "challenge_format": {
                      "encoding": "DECIMAL",
                      "min": 6,
                      "max": 6
                  },
                  "response_format": {
                      "encoding": "DECIMAL",
                      "length": 6
                  }
              },
              "data": {
                  "time": 0,
                  "time_interval": 120000
              },
              "pin_policy": {
                  "pin_usage_mode": "LOCAL",
                  "max_failed_attempts": 5,
                  "min_length": 4,
                  "max_length": 4,
                  "pin_encoding": "DECIMAL"
              },
              "created_at": "2019-04-06T00:12:52.000+0000",
              "updated_at": "2019-04-06T00:12:59.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/2"
                  },
                  "enrollment-string": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/2/enrollment-string"
                  },
                  "act-code": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/2/act-code"
                  }
              }
          },
          {
              "id": 35,
              "serial_no": "010100000035",
              "token_status": "ACTIVE",
              "token_type": "FOR_EVENT",
              "success_counter": 0,
              "failed_counter": 0,
              "algorithm_parameters": {
                  "suite": "SHA1",
                  "challenge_format": null,
                  "response_format": {
                      "encoding": "DECIMAL",
                      "length": 6
                  }
              },
              "data": {
                  "time": 0,
                  "time_interval": 30000
              },
              "pin_policy": {
                  "pin_usage_mode": "LOCAL",
                  "max_failed_attempts": 5,
                  "min_length": 4,
                  "max_length": 4,
                  "pin_encoding": "DECIMAL"
              },
              "created_at": "2019-04-09T22:30:07.000+0000",
              "updated_at": "2019-04-29T04:56:27.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/35"
                  },
                  "validate": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/35/validate"
                  }
              }
          },
          {
              "id": 38,
              "serial_no": "010100000038",
              "token_status": "ACTIVE",
              "token_type": "FOR_EVENT",
              "success_counter": 0,
              "failed_counter": 0,
              "algorithm_parameters": {
                  "suite": "SHA1",
                  "challenge_format": null,
                  "response_format": {
                      "encoding": "DECIMAL",
                      "length": 6
                  }
              },
              "data": {
                  "time": 0,
                  "time_interval": 30000
              },
              "pin_policy": {
                  "pin_usage_mode": "LOCAL",
                  "max_failed_attempts": 5,
                  "min_length": 4,
                  "max_length": 4,
                  "pin_encoding": "DECIMAL"
              },
              "created_at": "2019-04-09T22:34:52.000+0000",
              "updated_at": "2019-05-01T00:58:14.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/38"
                  },
                  "validate": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/38/validate"
                  }
              }
          },
          {
              "id": 39,
              "serial_no": "010100000039",
              "token_status": "ACTIVE",
              "token_type": "FOR_EVENT",
              "success_counter": 2,
              "failed_counter": 0,
              "algorithm_parameters": {
                  "suite": "SHA1",
                  "challenge_format": null,
                  "response_format": {
                      "encoding": "DECIMAL",
                      "length": 6
                  }
              },
              "data": {
                  "time": 0,
                  "time_interval": 30000
              },
              "pin_policy": {
                  "pin_usage_mode": "LOCAL",
                  "max_failed_attempts": 5,
                  "min_length": 4,
                  "max_length": 4,
                  "pin_encoding": "DECIMAL"
              },
              "created_at": "2019-04-09T22:36:05.000+0000",
              "updated_at": "2019-05-01T03:20:13.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/39"
                  },
                  "validate": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/39/validate"
                  }
              }
          },
          {
              "id": 40,
              "serial_no": "010100000040",
              "token_status": "ACTIVE",
              "token_type": "CHALLENGE_RESPONSE",
              "success_counter": 3,
              "failed_counter": 3,
              "algorithm_parameters": {
                  "suite": "SHA1",
                  "challenge_format": {
                      "encoding": "DECIMAL",
                      "min": 6,
                      "max": 6
                  },
                  "response_format": {
                      "encoding": "DECIMAL",
                      "length": 6
                  }
              },
              "data": {
                  "time": 0,
                  "time_interval": 120000
              },
              "pin_policy": {
                  "pin_usage_mode": "LOCAL",
                  "max_failed_attempts": 5,
                  "min_length": 4,
                  "max_length": 4,
                  "pin_encoding": "DECIMAL"
              },
              "created_at": "2019-04-09T22:37:17.000+0000",
              "updated_at": "2019-05-01T16:58:48.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/40"
                  },
                  "validate": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/40/validate"
                  },
                  "challenge": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/40/challenge"
                  }
              }
          },
          {
              "id": 81,
              "serial_no": "010100000081",
              "token_status": "ACTIVE",
              "token_type": "CHALLENGE_RESPONSE",
              "success_counter": 2,
              "failed_counter": 0,
              "algorithm_parameters": {
                  "suite": "SHA1",
                  "challenge_format": {
                      "encoding": "DECIMAL",
                      "min": 6,
                      "max": 6
                  },
                  "response_format": {
                      "encoding": "DECIMAL",
                      "length": 6
                  }
              },
              "data": {
                  "time": 0,
                  "time_interval": 120000
              },
              "pin_policy": {
                  "pin_usage_mode": "LOCAL",
                  "max_failed_attempts": 5,
                  "min_length": 4,
                  "max_length": 4,
                  "pin_encoding": "DECIMAL"
              },
              "created_at": "2019-04-22T16:00:15.000+0000",
              "updated_at": "2019-04-22T16:03:23.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/81"
                  },
                  "validate": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/81/validate"
                  },
                  "challenge": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/81/challenge"
                  }
              }
          }
      ],
      "_links": {
          "first": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens?page=0&size=20"
          },
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens?page=0&size=20"
          },
          "next": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens?page=1&size=20"
          },
          "last": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens?page=6&size=20"
          }
      },
      "_page": {
          "size": 20,
          "total_elements": 132,
          "total_pages": 7,
          "number": 0
      }
  }
`}</SyntaxHighlighter>
                      </div>
                      <p>Obteniendo como <code>RESPONSE</code> el siguiente contenido:</p>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Tipo</th>
                            <th>Descripción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><strong>_content</strong></td>
                            <td>array</td>
                            <td>Colección de objetos licencia.</td>
                          </tr>
                          <tr>
                            <td><strong>_links</strong></td>
                            <td>object</td>
                            <td>Colección de objetos link.</td>
                          </tr>
                          <tr>
                            <td><strong>_page</strong></td>
                            <td>object</td>
                            <td>Objeto con la metainformación de paginación.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div id="token" className="section-block">
                      <h3 className="block-title">Token</h3>
                      <p>Puede realizar una petición de tipo <code>GET</code>a
                        <code>https://sandbox.tesseract.mx/api/v2/institution/licenses/{"{"}license_id{"}"}/tokens/{"{"}token_id{"}"}</code>
                        para recuperar un token especifico perteneciente a una licencia, donde: </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>license_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para licencia perteneciente a la institución</td>
                            </tr>
                            <tr>
                              <td><strong>token_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para el token perteneciente a la licencia</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/1 \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{` GET /api/v2/institution/licenses/1/tokens/1 HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>ENCABEZADO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Status: 200 OK
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`      {
      "id": 1,
      "serial_no": "010100000001",
      "token_status": "BLOCKED",
      "token_type": "CHALLENGE_RESPONSE",
      "success_counter": 0,
      "failed_counter": 0,
      "algorithm_parameters": {
          "suite": "SHA1",
          "challenge_format": {
              "encoding": "DECIMAL",
              "min": 6,
              "max": 6
          },
          "response_format": {
              "encoding": "DECIMAL",
              "length": 6
          }
      },
      "data": {
          "time": 0,
          "time_interval": 120000
      },
      "pin_policy": {
          "pin_usage_mode": "LOCAL",
          "max_failed_attempts": 5,
          "min_length": 4,
          "max_length": 4,
          "pin_encoding": "DECIMAL"
      },
      "created_at": "2019-04-06T00:12:49.000+0000",
      "updated_at": "2019-05-13T18:49:37.000+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/1"
          }
      }
  }
`}</SyntaxHighlighter>
                        </div>
                        <p>A continuación se muestra la descripción del <code>RESPONSE</code> del recurso recuperado.</p>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>id</strong></td>
                              <td>number</td>
                              <td>Identificador único del token.</td>
                            </tr>
                            <tr>
                              <td><strong>serial_no</strong></td>
                              <td>string</td>
                              <td>Número de serie único del token.</td>
                            </tr>
                            <tr>
                              <td><strong>token_status</strong></td>
                              <td>string</td>
                              <td>Estado actual del token.</td>
                            </tr>
                            <tr>
                              <td><strong>token_type</strong></td>
                              <td>string</td>
                              <td>Tipo de token.</td>
                            </tr>
                            <tr>
                              <td><strong>success_counter</strong></td>
                              <td>number</td>
                              <td>Número transacciones exitosas del token.</td>
                            </tr>
                            <tr>
                              <td><strong>failed_counter</strong></td>
                              <td>number</td>
                              <td>Número transacciones fallidas del token.</td>
                            </tr>
                            <tr>
                              <td><strong>algorithm_parameters</strong></td>
                              <td>object</td>
                              <td>Parametros del algoritmo implementado por el token.</td>
                            </tr>
                            <tr>
                              <td><strong>data</strong></td>
                              <td>object</td>
                              <td>Datos del token.</td>
                            </tr>
                            <tr>
                              <td><strong>policy</strong></td>
                              <td>object</td>
                              <td>Politicas del token.</td>
                            </tr>
                            <tr>
                              <td><strong>created_at</strong></td>
                              <td>string</td>
                              <td>Representación de la fecha de creación del token en la zona horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>updated_at</strong></td>
                              <td>string</td>
                              <td>Representación de la ultima fecha de actualización del token en la zona. horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>_links</strong></td>
                              <td>object</td>
                              <td>Contiene los links a recursos asociados</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>A continuación se describen los campos correspondientes a <code>algorithm_parameters</code>,
                        <code>data</code> y <code>policy</code>.</p>
                    </div>
                    <div id="algorithm-parameters" className="section-block">
                      <h3 className="block-title">Algorithm Parameters</h3>
                      <div className="section-block">
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`  "algorithm_parameters": {
      "suite": "SHA1",
      "challenge_format": {
          "encoding": "DECIMAL",
          "min": 6,
          "max": 6
      },
      "response_format": {
          "encoding": "DECIMAL",
          "length": 6
      }
  }`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>suite</strong></td>
                              <td>string</td>
                              <td>Define características adicionales del algoritmo utilizado, que son específicas del
                                algoritmo los cuales pueden ser <code>SHA1</code>, <code>SHA256</code> y <code>SHA512</code>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>challenge_format</strong></td>
                              <td>string</td>
                              <td>Define las características de la prueba en un escenario de uso de
                                <code>CHALLENGE RESPONSE</code>, el cual es <code>null</code> si el tipo de token es
                                <code>FOR_EVENT</code> </td>
                            </tr>
                            <tr>
                              <td><strong>response_format</strong></td>
                              <td>string</td>
                              <td>Objeto que define las características del resultado de un cálculo y define el formato de la
                                OTP o del <code>CHALLENGE RESPONSE</code></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="challenge-format" className="section-block">
                      <h3 className="block-title">Challenge Format</h3>
                      <div className="section-block">
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`  "challenge_format": {
      "encoding": "DECIMAL",
      "min": 6,
      "max": 6
  }
`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>encoding</strong></td>
                              <td>string</td>
                              <td>Este atributo, define la codificación del desafío aceptado por el dispositivo y DEBE ser uno
                                de los siguientes valores: <code>DECIMAL</code>, <code>HEXADECIMAL</code>,
                                <code>ALPHANUMERIC</code> y <code>BASE64</code></td>
                            </tr>
                            <tr>
                              <td><strong>min</strong></td>
                              <td>number</td>
                              <td>Este atributo define el tamaño mínimo de desafío aceptado por el dispositivo para el modo CR
                                y DEBE incluirse. Si el atributo <code>encoding</code> está establecido en
                                <code>DECIMAL</code>, <code>HEXADECIMAL</code> o <code>ALPHANUMERIC</code>, este valor
                                indica el número mínimo de dígitos / caracteres. Si el atributo <code>encoding</code> está
                                establecido en <code>BASE64</code> o <code>BINARY</code>, este valor indica el número mínimo
                                de bytes del valor no codificado.</td>
                            </tr>
                            <tr>
                              <td><strong>max</strong></td>
                              <td>number</td>
                              <td>Este atributo define el tamaño máximo del desafío aceptado por el dispositivo para el modo
                                CR y DEBE incluirse. Si el atributo <code>encoding</code> está establecido en
                                <code>DECIMAL</code>, <code>HEXADECIMAL</code> o <code>ALPHANUMERIC</code>,este valor indica
                                el número mínimo de dígitos / caracteres. Si el atributo encoding está establecido en BASE64
                                o BINARY, este valor indica el número mínimo de bytes del valor no codificado. </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="response-format" className="section-block">
                      <h3 className="block-title">Response Format</h3>
                      <div className="section-block">
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`  "response_format": {
      "encoding": "DECIMAL",
      "length": 6
  }`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>encoding</td>
                              <td>string</td>
                              <td>Este atributo, define la codificación del desafío aceptado por el dispositivo y DEBE ser uno
                                de los siguientes valores: <code>DECIMAL</code>, <code>HEXADECIMAL</code>,
                                <code>ALPHANUMERIC</code> y <code>BASE64</code>.</td>
                            </tr>
                            <tr>
                              <td>length</td>
                              <td>number</td>
                              <td>Este atributo define el tamaño mínimo de desafío aceptado por el dispositivo para el modo CR
                                y DEBE incluirse. Si el atributo <code>encoding</code> está establecido en
                                <code>DECIMAL</code>, <code>HEXADECIMAL</code> o <code>ALPHANUMERIC</code>, este valor
                                indica el número mínimo de dígitos / caracteres. Si el atributo encoding está establecido en
                                BASE64 o BINARY, este valor indica el número mínimo de bytes del valor no codificado.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="data" className="section-block">
                      <h3 className="block-title">Data</h3>
                      <div className="section-block">
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`  "data": {
      "time": 0,
      "time_interval": 1200000
  }`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>time</strong></td>
                              <td>string</td>
                              <td>Contiene el tiempo para OTP basado en el tiempo de los algoritmos. (Si se usan intervalos de
                                tiempo, este elemento lleva la cantidad de intervalos de tiempo transcurridos desde un
                                punto de inicio específico, normalmente depende del algoritmo)</td>
                            </tr>
                            <tr>
                              <td><strong>time_inteval</strong></td>
                              <td>number</td>
                              <td>Este elemento lleva el valor del intervalo de tiempo para algoritmos OTP basados en tiempo
                                en milisegundos (un valor típico para esto sería 30000, lo que indica un intervalo de tiempo
                                de 30000 milisegundos)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="pin-policy" className="section-block">
                      <h3 className="block-title">PIN Policy</h3>
                      <div className="section-block">
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`  "pin_policy": {
      "pin_usage_mode": "LOCAL",
      "max_failed_attempts": 5,
      "min_length": 4,
      "max_length": 4,
      "pin_encoding": "DECIMAL"
  }`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>pin_usage_mode</strong></td>
                              <td>string</td>
                              <td>Este atributo obligatorio indica la forma en que se utiliza el PIN durante el uso de la
                                clave. Se definen los siguientes valores <code>Local</code>, <code>Preappend</code>,
                                <code>Append</code> y <code>Algorithmic</code></td>
                            </tr>
                            <tr>
                              <td><strong>max_failed_attempts</strong></td>
                              <td>number</td>
                              <td>Este atributo indica la cantidad máxima de veces que se puede ingresar el PIN
                                incorrectamente antes de que NO SEA POSIBLE usar la clave (los valores razonables típicos
                                están en el rango entero positivo de al menos 2 y no más de 10).</td>
                            </tr>
                            <tr>
                              <td><strong>min_length</strong></td>
                              <td>number</td>
                              <td>Este atributo indica la longitud mínima de un PIN que se puede configurar para proteger la
                                clave asociada. NO DEBE ser posible establecer un PIN más corto que este valor. Si el
                                atributo <code>pin_encoding</code> está establecido en <code>DECIMAL</code>,
                                <code>HEXADECIMAL</code> o <code>ALPHANUMERIC</code>, este valor indica el número de dígitos
                                / caracteres. Si el atributo <code>pin_encoding</code> está establecido en
                                <code>BASE64</code> o <code>BINARY</code>, este valor indica el número de bytes del valor no
                                codificado.</td>
                            </tr>
                            <tr>
                              <td><strong>max_length</strong></td>
                              <td>number</td>
                              <td>Este atributo indica la longitud máxima de un PIN que se puede configurar para proteger la
                                clave asociada. NO DEBE ser posible establecer un PIN más largo que este valor. Si el
                                atributo <code>pin_encoding</code> está establecido en <code>DECIMAL</code>,
                                <code>HEXADECIMAL</code> o <code>ALPHANUMERIC</code>, este valor indica el número de dígitos
                                / caracteres. Si el atributo <code>pin_encoding</code> está establecido en
                                <code>BASE64</code> o <code>BINARY</code>, este valor indica el número de bytes del valor no
                                codificado. </td>
                            </tr>
                            <tr>
                              <td><strong>pin_encoding</strong></td>
                              <td>string</td>
                              <td>Este atributo indica la codificación del PIN y DEBE ser uno de los valores:
                                <code>DECIMAL</code>, <code>HEXADECIMAL</code>, <code>ALPHANUMERIC</code> y
                                <code>BASE64</code></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="create-token" className="section-block">
                      <h3 className="block-title">Crear un nuevo Token</h3>
                      <div className="callout-block callout-danger">
                        <div className="icon-holder">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </div>
                        <div className="content">
                          Únicamente puedes crear tokens si tu licencia fue previamente activada por <strong>Tesseract</strong>.
                        </div>
                      </div>
                      <p>Para crear un token es necesario enviar una petición <code>POST</code> a la siguiente URL:<br />
                        <code>https://sandbox.tesseract.mx/api/v2/institution/licenses/{"{"}licenses_id{"}"}/tokens</code>, donde:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>license_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para licencia perteneciente a la institución</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>Y con el siguiente <code>BODY</code> mínimo requerido:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>token_type</strong></td>
                              <td>string</td>
                              <td>Tipo de token a crear: <em><code>CHALLENGE_RESPONSE</code></em> ó
                                <em><code>FOR_EVENT</code></em> </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/1 \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`POST /api/v2/institution/licenses/1/tokens/ HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='json' style={tomorrowNight}>{`  {
      "token_type": "FOR_EVENT"
  }`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`Location: https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/3
  Status: 201`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`{
      "id": 261,
      "serial_no": "010100000261",
      "token_status": "UNASSIGNED",
      "token_type": "FOR_EVENT",
      "success_counter": 0,
      "failed_counter": 0,
      "algorithm_parameters": {
          "suite": "SHA1",
          "challenge_format": null,
          "response_format": {
              "encoding": "DECIMAL",
              "length": 6
          }
      },
      "data": {
          "time": 0,
          "time_interval": 30000
      },
      "pin_policy": {
          "pin_usage_mode": "LOCAL",
          "max_failed_attempts": 5,
          "min_length": 4,
          "max_length": 4,
          "pin_encoding": "DECIMAL"
      },
      "created_at": "2019-06-13T17:20:22.463+0000",
      "updated_at": "2019-06-13T17:20:22.463+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/261"
          },
          "enrollment-string": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/261/enrollment-string"
          }
      }
  }
`}</SyntaxHighlighter>
                        </div>

                      </div>
                      <p>A continuación se muestra la descripción del <code>RESPONSE</code> del recurso recuperado.</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>id</strong></td>
                              <td>number</td>
                              <td>Identificador único del token.</td>
                            </tr>
                            <tr>
                              <td><strong>serial_no</strong></td>
                              <td>string</td>
                              <td>Número de serie único del token.</td>
                            </tr>
                            <tr>
                              <td><strong>token_status</strong></td>
                              <td>string</td>
                              <td>Estado actual del token.</td>
                            </tr>
                            <tr>
                              <td><strong>token_type</strong></td>
                              <td>string</td>
                              <td>Tipo de token.</td>
                            </tr>
                            <tr>
                              <td><strong>success_counter</strong></td>
                              <td>number</td>
                              <td>Número transacciones exitosas del token.</td>
                            </tr>
                            <tr>
                              <td><strong>failed_counter</strong></td>
                              <td>number</td>
                              <td>Número transacciones fallidas del token.</td>
                            </tr>
                            <tr>
                              <td><strong>algorithm_parameters</strong></td>
                              <td>object</td>
                              <td>Parametros del algoritmo implementado por el token.</td>
                            </tr>
                            <tr>
                              <td><strong>data</strong></td>
                              <td>object</td>
                              <td>Data del token.</td>
                            </tr>
                            <tr>
                              <td><strong>policy</strong></td>
                              <td>object</td>
                              <td>Politicas del token.</td>
                            </tr>
                            <tr>
                              <td><strong>created_at</strong></td>
                              <td>string</td>
                              <td>Representación de la fecha de creación del token en la zona horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>updated_at</strong></td>
                              <td>string</td>
                              <td>Representación de la ultima fecha de actualización del token en la zona. horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>_links</strong></td>
                              <td>object</td>
                              <td>Contiene los links a recursos asociados.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="advance-parameters" className="section-block">
                      <h3 className="block-title">Parámetros Avanzados</h3>
                      <p>Una extensión para la creación de un token incluye los siguientes parametros en el <code>BODY</code> de
                        nuestro <code>REQUEST</code>.</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>token_type</strong></td>
                              <td>string</td>
                              <td>Tipo de token.</td>
                            </tr>
                            <tr>
                              <td><strong>algorithm_parameters (opcional)</strong></td>
                              <td>object (opcional)</td>
                              <td>Parametros del algoritmo implementado por el token.</td>
                            </tr>
                            <tr>
                              <td><strong>data (opcional)</strong></td>
                              <td>object (opcional)</td>
                              <td>Data del token.</td>
                            </tr>
                            <tr>
                              <td><strong>policy (opcional)</strong></td>
                              <td>object (opcional)</td>
                              <td>Politicas asociadas al token.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X POST \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/ \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\
  -d '{
        "token_type": "CHALLENGE_RESPONSE",
        "algorithm_parameters": {
            "suite": "SHA256",
            "challenge_format": {
                "encoding": "DECIMAL",
                "min": 8,
                "max": 8
            },
            "response_format": {
                "encoding": "DECIMAL",
                "length": 8
            }
        },
        "data": {
            "time": 0,
            "time_interval": 600000
        },
        "pin_policy": {
            "pin_usage_mode": "LOCAL",
            "max_failed_attempts": 5,
            "min_length": 4,
            "max_length": 16,
            "pin_encoding": "DECIMAL"
        }
    }'`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`POST /api/v2/institution/licenses/1/tokens/ HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='json' style={tomorrowNight}>{`  {
      "token_type": "CHALLENGE_RESPONSE",
      "algorithm_parameters": {
          "suite": "SHA256",
          "challenge_format": {
              "encoding": "DECIMAL",
              "min": 8,
              "max": 8
          },
          "response_format": {
              "encoding": "DECIMAL",
              "length": 8
          }
      },
      "data": {
          "time": 0,
          "time_interval": 600000
      },
      "pin_policy": {
          "pin_usage_mode": "LOCAL",
          "max_failed_attempts": 5,
          "min_length": 4,
          "max_length": 16,
          "pin_encoding": "DECIMAL"
      }
  }`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`Location: https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/262
  Status: 201`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`      {
      "id": 262,
      "serial_no": "010100000262",
      "token_status": "UNASSIGNED",
      "token_type": "CHALLENGE_RESPONSE",
      "success_counter": 0,
      "failed_counter": 0,
      "algorithm_parameters": {
          "suite": "SHA256",
          "challenge_format": {
              "encoding": "DECIMAL",
              "min": 8,
              "max": 8
          },
          "response_format": {
              "encoding": "DECIMAL",
              "length": 8
          }
      },
      "data": {
          "time": 0,
          "time_interval": 600000
      },
      "pin_policy": {
          "pin_usage_mode": "LOCAL",
          "max_failed_attempts": 5,
          "min_length": 4,
          "max_length": 16,
          "pin_encoding": "DECIMAL"
      },
      "created_at": "2019-06-13T18:48:08.270+0000",
      "updated_at": "2019-06-13T18:48:08.270+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/262"
          },
          "enrollment-string": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/262/enrollment-string"
          }
      }
  }`}</SyntaxHighlighter>
                        </div>

                      </div>
                    </div>
                    <div id="enrollment-string" className="section-block">
                      <h3 className="block-title">Enrollment String</h3>
                      <p>Para recuperar el <code>Enrollment String</code> de un token procedemos a realizar<br />una petición
                        <code>GET</code> a la siguiente URL
                        <code>https://sandbox.tesseract.mx/api/v2/institution/licenses/{"{"}license_id{"}"}/tokens/{"{"}token_id{"}"}/enrollment-string</code><br />donde:
                      </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>license_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para licencia perteneciente a la institución.</td>
                            </tr>
                            <tr>
                              <td><strong>token_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para el token perteneciente a la licencia.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`   curl -X GET \\
   https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/262/enrollment-string \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`GET /api/v2/institution/licenses/1/tokens/262/enrollment-string HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Content-Type: application/json;charset=UTF-8
  Status: 200 Ok`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`{
  "enrollment_string": "MWUzMjZhNTg0MzhkNjRlNWQzYjJjYTI2MTBkMGUwOGMyMTlmNzc2OTQ4MWQzYmM0NDRhMmNkMTNmN2I0ZDFkZGRjYjMzNzg2MTg0NmViYjU4NzRiMjdjNTc3NDdlZjU5NzI0NzE5YjQ2NjAzZjNkNDFmMzk0ZTY4ZmI5OWUxZmU0NmI0ZWE2N2M4ZjRhYjczZTljOGYzMWY3YmQ3M2E2YTgzMjU5YzkwZThmYjYzN2Y5NTQ2MjIyZjA4ZmNjNDIzMDRhZjRlYmUxZWEwOGYxNTBiMGVhN2JjNjI4ZmRmYmU2NWMwMjkzODNlYWU2NWQ1MDQ4NjA5NTMwMDA3MmIwM2NlODJkMGEwYjYwNzBhZGQyZmI0NTI2MjVkMjZkZDFhYjk4ZjdhNjczNjNkNDU5MTZhZjdkMzdmYzFkMzE1MzE1YzBjZjVjNjE5ZmE4MjRmZTdkZDA0NTI3MDQ4ODY5OWFiMzAwOWMwM2NkNzE4ZDAxZjM2MzVkZGNjNTBmZTBjYmEzMThjMWQxM2YyYzIwOTA2YzZlZjExZjRhMmZhNzliZGIwMzVkN2ZjMmVmMmM1YWNlM2ZkNWMwNmEzYjQ3ZGZlNjE5MDdiNWMzMjE4Y2U="
  }`}</SyntaxHighlighter>
                        </div>

                      </div>
                      <p>A continuación se muestra la descripción del RESPONSE del recurso recuperado.</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>enrollment_string</strong></td>
                              <td>string</td>
                              <td>Cadena de enrolamiento codificada en <code>Base64</code> la cual permite activar un token,
                                debe ser solicitada antes de solicitar la <code>clave de activación</code></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="act-code" className="section-block">
                      <h3 className="block-title">Act Code</h3>
                      <p>Para recuperar el <code>Act Code</code> de un token procedemos a realizar<br />una petición
                        <code>GET</code> a la siguiente URL:<br />
                        <code>https://sandbox.tesseract.mx/api/v2/institution/licenses/{"{"}license_id{"}"}/tokens/{"{"}token_id{"}"}/act-code</code>,<br />donde:
                      </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>license_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para licencia perteneciente a la institución.</td>
                            </tr>
                            <tr>
                              <td><strong>token_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para el token perteneciente a la licencia.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/262/act-code \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`GET /api/v2/institution/licenses/1/tokens/262/act-code HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Content-Type: application/json;charset=UTF-8
  Status: 200 Ok`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`{
  "act_code": "44141848"
}`}</SyntaxHighlighter>
                        </div>

                      </div>
                      <p>A continuación se muestra la descripción del <code>RESPONSE</code> del recurso recuperado.</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>act_code</strong></td>
                              <td>string</td>
                              <td>Clave de activación de 8 dígitos la cual permite activar una cadena de enrolamiento, la cual
                                debe ser solicitada despues de solicitar el <code>enrollment_string</code></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="challenge" className="section-block">
                      <h3 className="block-title">Challenge</h3>
                      <p>Para recuperar el <code>Challenge</code> de un token procedemos a realizar<br />una petición
                        <code>GET</code> a la siguiente URL
                        <code>https://sandbox.tesseract.mx/api/v2/institution/licenses/{"{"}license_id{"}"}/tokens/{"{"}token_id{"}"}/challenge</code>,<br />donde:
                      </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>license_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para licencia perteneciente a la institución.</td>
                            </tr>
                            <tr>
                              <td><strong>token_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para el token perteneciente a la licencia.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/262/challenge \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`GET /api/v2/institution/licenses/1/tokens/262/challenge HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Content-Type: application/json;charset=UTF-8
  Status: 200 Ok`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`{
  "question": "99907755",
  "valid_from": "2019-06-13T21:56:34.759+0000",
  "valid_until": "2019-06-13T22:06:34.759+0000"
  }`}</SyntaxHighlighter>
                        </div>

                      </div>
                      <p>A continuación se muestra la descripción del <code>RESPONSE</code> del recurso recuperado.</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>valid_from</strong></td>
                              <td>number</td>
                              <td>Representación de la fecha desde la cual es válido el <code>challenge</code> del token en la
                                zona horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>expiration</strong></td>
                              <td>number</td>
                              <td>Representación de la fecha en la cual expira el <code>challenge</code> del token en la zona
                                horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>question</strong></td>
                              <td>string</td>
                              <td>Desafio de un token el cual puede ser <code>DECIMAL</code>, <code>HEXADECIMAL</code> o
                                <code>ALPHANUMERIC</code>, este valor indica el número de dígitos / caracteres. Si el
                                atributo <code>pin_encoding</code> está establecido en <code>BASE64</code> o
                                <code>BINARY</code>, este valor indica el número de bytes del valor no codificado.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="validate-token" className="section-block">
                      <h3 className="block-title">Validate Token Result</h3>
                      <p>Para validar un OTP/Response deberás envíar una solicitud <code>POST</code> a la siguiente URL:<br />
                        <code>https://sandbox.tesseract.mx/api/v2/institution/licenses/{"{"}licenses_id{"}"}/tokens/{"{"}token_id{"}"}/validate</code>,
                        en donde:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>license_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para licencia perteneciente a la institución.</td>
                            </tr>
                            <tr>
                              <td><strong>token_id</strong></td>
                              <td>number</td>
                              <td>Identificador único para el token perteneciente a la licencia.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>Y con el siguiente <code>BODY</code> mínimo requerido:</p>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Tipo</th>
                            <th>Descripción </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><strong>result</strong></td>
                            <td>string</td>
                            <td>Resultado generado mediante Tesseract SDK Android/IOS.</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`   curl -X POST \\
  https://sandbox.tesseract.mx/api/v2/institution/licenses/1/tokens/267/validate \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`POST /api/v2/institution/licenses/1/tokens/267/validate HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='json' style={tomorrowNight}>{`  {
  "result":"038431"
  }`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Location: https://sandbox.tesseract.mx/api/v2/institution/licenses/267/tokens/1/validate
  Status: 200`}</SyntaxHighlighter>
                        </div></div>
                      <p>Obtendremos un <code>RESPONSE BODY</code> con la siguiente información:</p>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`  {
  "failed_intents": 0,
  "max_intents": 5,
  "success": true
  }`}</SyntaxHighlighter>
                        </div>

                      </div>

                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>failed_intents</strong></td>
                              <td>number</td>
                              <td>Número actual de intentos fallidos.</td>
                            </tr>
                            <tr>
                              <td><strong>max_intents</strong></td>
                              <td>number</td>
                              <td>Número máximo de intentos fallidos antes de revocar el token.</td>
                            </tr>
                            <tr>
                              <td><strong>success</strong></td>
                              <td>boolean</td>
                              <td>Exito de la validación.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="token-type" className="section-block">
                      <h3 className="block-title">Token Type</h3>
                      <p>Tesseract soporta actualmente los siguientes tipos de tokens:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>CHALLENGE_RESPONSE</strong></td>
                              <td>Este token esta basado en un protocolo de desafío-respuesta en donde el desafío
                                proporcionado por Tesseract API es únicamente válido durante 120 segundos (por
                                defecto)tiempo que puede ser customizado, y cada vez que se solicita un nuevo desafío este
                                reinicializa su validez.</td>
                            </tr>
                            <tr>
                              <td><strong>FOR_EVENT</strong></td>
                              <td>Este token se basa en la sincronización para generar cada 30 seg (por defecto)tiempo en que
                                puede ser customizado un nuevo OTP.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="token-status" className="section-block">
                      <h3 className="block-title">Token Status</h3>
                      <p>Los estados de un token permiten realizar ciertas acciones para autorizar el consumo de recursos
                        asociados, a continuación se describe los diferentes tipos de estados de un token.</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Estado</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>UNASSIGNED</strong></td>
                              <td>Estado inicial de todo token al ser creado, este estado hace referencia a que el token esta
                                sin asignar, solo puede estar en este estado una vez.</td>
                            </tr>
                            <tr>
                              <td><strong>WAITING</strong></td>
                              <td>Estado al que únicamente se llega tras obtener la cadena de enrolamiento, este estado hace
                                referencia a estar en espera de ser asignado.</td>
                            </tr>
                            <tr>
                              <td><strong>ASSIGNED</strong></td>
                              <td>Estado al que únicamente se llega tras obtener la clave de activación, este estado hace
                                referencia a estar asignado.</td>
                            </tr>
                            <tr>
                              <td><strong>ACTIVE</strong></td>
                              <td>Estado al cual se llega por primera vez tras ser activado por Tesseract SDK, el estado
                                anterior para que esto ocurra es ASSIGNED. Sin embargo tras ser activado el token puede
                                pasar a un estado REVOKED en el cual el token puede ser reactivado.</td>
                            </tr>
                            <tr>
                              <td><strong>REVOKED</strong></td>
                              <td>Estado al que únicamente se llega tras ser activado y haber fallado en más de 5 ocasiones
                                (por default) con la respuesta OTP.</td>
                            </tr>
                            <tr>
                              <td><strong>BLOCKED</strong></td>
                              <td>En este estado el token queda inutilizado y final por lo que no puede modificarse o
                                reutilizarse de nuevo. Al suceder esta acción se libera un token de su stock total de la
                                licencia utilizada. Por lo que en caso de que el token quede comprometido este queda
                                liberado para generar un nuevo token.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  <section id="apps-section" className="doc-section">
                    <div id="apps" className="section-block">
                      <h2 className="block-title">Aplicaciones</h2>
                      <p>Puede realizar una petición de tipo <code>GET</code> a
                        <code>https://sandbox.tesseract.mx/api/v2/institution/apps</code> para observar las aplicaciones de una
                        institución en específico.</p>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/apps \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  GET /api/v2/institution/apps HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Content-Type: application/json;charset=UTF-8
  Status: 200 OK
`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`    {
      "_content": [
          {
              "id": 2,
              "name": "Application Access",
              "access_key_id": null,
              "secret_access_key": null,
              "enabled": true,
              "roles": [
                  "APP_ROLE",
                  "PARTITION_ROLE",
                  "ROLE_ROLE",
                  "AES_ROLE",
                  "AUTHORITY_ROLE",
                  "KEY_ROLE",
                  "AUTH_ROLE",
                  "DES_ROLE",
                  "RSA_ROLE",
                  "INSTITUTION_ROLE",
                  "LICENSE_ROLE",
                  "TOKEN_ROLE",
                  "CERTIFICATE_ROLE",
                  "HASH_ROLE"
              ],
              "created_at": "2019-04-05T15:52:28.000+0000",
              "updated_at": "2019-04-05T15:52:28.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/apps/2"
                  }
              }
          },
          {
              "id": 12,
              "name": "Documentation Access",
              "access_key_id": null,
              "secret_access_key": null,
              "enabled": true,
              "roles": [
                  "APP_ROLE",
                  "PARTITION_ROLE",
                  "ROLE_ROLE",
                  "AES_ROLE",
                  "KEY_ROLE",
                  "AUTH_ROLE",
                  "DES_ROLE",
                  "RSA_ROLE",
                  "INSTITUTION_ROLE",
                  "LICENSE_ROLE",
                  "TOKEN_ROLE",
                  "CERTIFICATE_ROLE",
                  "HASH_ROLE"
              ],
              "created_at": "2019-06-11T17:18:49.000+0000",
              "updated_at": "2019-06-11T17:18:49.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/apps/12"
                  }
              }
          }
      ],
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/apps?page=0&size=20"
          }
      },
      "_page": {
          "size": 20,
          "total_elements": 3,
          "total_pages": 1,
          "number": 0
      }
  }
`}</SyntaxHighlighter>
                        </div>

                      </div>
                      <p>A continuación, se muestra la descripción del <code>RESPONSE</code> del recurso recuperado.</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>_content</strong></td>
                              <td>array</td>
                              <td>Colección de objetos app.</td>
                            </tr>
                            <tr>
                              <td><strong>_links</strong></td>
                              <td>object</td>
                              <td>Contiene los links a recursos asociados.</td>
                            </tr>
                            <tr>
                              <td><strong>_page</strong></td>
                              <td>object</td>
                              <td>Objeto con la metainformación de paginación. </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="get-app" className="section-block">
                      <h3 className="block-title">Recuperar una Aplicación</h3>
                      <p>Puede realizar una petición de tipo <code>GET</code> a
                        <code>https://sandbox.tesseract.mx/api/v2/institution/apps/{"{"}id{"}"}</code> para observar una aplicación
                        específica del conjunto de aplicaciones registradas de una institución, donde:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>id</strong></td>
                              <td>number</td>
                              <td>Identificador único de la aplicación.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/apps/12 \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  GET /api/v2/institution/apps/12 HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  GET /api/v2/institution/apps/12 HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="json">json</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active json" language='json' style={tomorrowNight}>{`      {
      "id": 12,
      "name": "Documentation Access",
      "access_key_id": null,
      "secret_access_key": null,
      "enabled": true,
      "roles": [
          "APP_ROLE",
          "PARTITION_ROLE",
          "ROLE_ROLE",
          "AES_ROLE",
          "KEY_ROLE",
          "AUTH_ROLE",
          "DES_ROLE",
          "RSA_ROLE",
          "INSTITUTION_ROLE",
          "LICENSE_ROLE",
          "TOKEN_ROLE",
          "CERTIFICATE_ROLE",
          "HASH_ROLE"
      ],
      "created_at": "2019-06-11T17:18:49.000+0000",
      "updated_at": "2019-06-11T17:18:49.000+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/apps/12"
          }
      }
  }`}</SyntaxHighlighter>
                        </div>

                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>id</strong></td>
                              <td>number</td>
                              <td>Identificador único de la aplicación.</td>
                            </tr>
                            <tr>
                              <td><strong>name</strong></td>
                              <td>string</td>
                              <td>Etiqueta de nombre para identificar una aplicación.</td>
                            </tr>
                            <tr>
                              <td><strong>access_key_id</strong></td>
                              <td>string</td>
                              <td>Llave única de acceso con la cual se realizarán las peticiones (véase Resguardo).
                                <strong>Por seguridad este valor siempre sera null.</strong></td>
                            </tr>
                            <tr>
                              <td><strong>secret_access_key</strong></td>
                              <td>string</td>
                              <td>Contraseña del usuario con la cual realizará las peticiones (véase Resguardo). <strong>Por
                                seguridad este valor siempre sera null.</strong></td>
                            </tr>
                            <tr>
                              <td><strong>enabled</strong></td>
                              <td>boolean</td>
                              <td>Dato lógico el cual indica <code>false</code> si la aplicación está desactivada y
                                <code>true</code> si la aplicación se encuentra activa. </td>
                            </tr>
                            <tr>
                              <td><strong>roles</strong></td>
                              <td>array</td>
                              <td>Colección que indica todos los roles que posee la aplicación.</td>
                            </tr>
                            <tr>
                              <td><strong>created_at</strong></td>
                              <td>string</td>
                              <td>Representación de la fecha de creación de la aplicación en la zona horaria UTC. </td>
                            </tr>
                            <tr>
                              <td><strong>updated_at</strong></td>
                              <td>string</td>
                              <td>Representación de la ultima fecha de actualización de la aplicación en la zona horaria UTC.
                              </td>
                            </tr>
                            <tr>
                              <td><strong>_links</strong></td>
                              <td>object</td>
                              <td>Contiene los links a recursos asociados.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="create-app" className="section-block">
                      <h3 className="block-title">Crear una nueva Aplicación</h3>
                      <p>Puede generar una petición de tipo <code>POST</code> a
                        <code>https://sandbox.tesseract.mx/api/v2/institution/apps</code> para realizar el registro de una
                        aplicación para su institución como se muestra a continuación, con el siguiente <code>BODY</code> mínimo
                        requerido:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>name</strong></td>
                              <td>string</td>
                              <td>Nombre de la aplicación a registrar.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`      curl -X POST \
  https://sandbox.tesseract.mx/api/v2/institution/apps \
  -H 'Accept: application/json' \
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \
  -d '{"name":"name_application"}'`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  POST /api/v2/institution/apps HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Content-Type: application/json;charset=UTF-8
  Status: 201 OK`}</SyntaxHighlighter>
                        </div></div>

                      <p><strong><em>La creación de la aplicación no continen <code>BODY RESPONSE</code>.</em></strong></p>
                      <div className="callout-block callout-danger">
                        <div className="icon-holder">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </div>
                        <div className="content">
                          <p>Cabe recalcar que una vez registrada la aplicación
                            se encuentra desactivada, se debe realizar la activación de la aplicación para comenzar con el uso del
                            API.</p>
                        </div>
                      </div>
                      <p>Para verificar que una aplicación ha sido creada, puede solicitar ver las aplicaciones registradas.
                        <a href="#apps">Aplicaciones</a></p>
                    </div>
                    <div id="update-app" className="section-block">
                      <h3 className="block-title">Actualizar una Aplicación</h3>
                      <p>Puede realizar una petición de tipo <code>PUT</code> a
                        <code>https://sandbox.tesseract.mx/api/v2/institution/apps/{"{"}id{"}"}</code> para actualizar un recurso, donde:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>id</strong></td>
                              <td>number</td>
                              <td>Identificador único de la aplicación.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X PUT https://sandbox.tesseract.mx/api/v2/institution/apps/14 \
  -H 'Accept: application/json' \
  -H 'authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \
  -d '{"name":"name_application"}'`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  PUT /api/v2/institution/apps HTTP/1.1
  Host: sandbox.tesseract.mx
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==
  Accept: application/json`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Content-Type: application/json;charset=UTF-8
  Status: 200 OK`}</SyntaxHighlighter>
                        </div></div>
                      <p>Para verificar que una aplicación ha sido modificada, puede solicitar ver las aplicaciones registradas. <a
                        href="#get-app">Recuperar una Aplicación</a></p>
                    </div>
                    <div id="delete-app" className="section-block">
                      <h3 className="block-title">Eliminar una Aplicación</h3>
                      <p>Puede generar una petición de tipo <code>DELETE</code> a
                        <code>https://sandbox.tesseract.mx/api/v2/institution/apps/{"{"}id{"}"}</code> para eliminar un recurso, donde:
                      </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>id</strong></td>
                              <td>number</td>
                              <td>Identificador único de la aplicación.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X DELETE \\
  https://sandbox.tesseract.mx/api/v2/institution/apps/14 \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="partition-section" className="doc-section">
                    <div id="partitions" className="section-block">
                      <h2 className="block-title">Partición</h2>
                      <p>Para recuperar la información de la partición realizamos una petición <code>GET</code> a la siguiente URL
                        <code>https://sandbox.tesseract.mx/api/v2/institution/partition</code>.</p>
                      <p>Obteniendo como <code>RESPONSE</code> el siguiente contenido:</p>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/partition \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  GET /api/v2/institution/partition HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Content-Type: application/json;charset=UTF-8
  Status: 200 Ok`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>RESPONSE BODY</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  {
      "slot": 4,
      "serial_number": "11343267252291",
      "label": "HA_TESSERACT",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/partition"
          },
          "keys": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/partition/keys"
          }
      }
  }`}</SyntaxHighlighter>
                        </div></div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>slot</strong></td>
                              <td>number</td>
                              <td>Número de slot de la partición.</td>
                            </tr>
                            <tr>
                              <td><strong>serial_number</strong></td>
                              <td>string</td>
                              <td>Número de serie de la partición.</td>
                            </tr>
                            <tr>
                              <td><strong>label</strong></td>
                              <td>string</td>
                              <td>Etiqueta de nombre común de la partición.</td>
                            </tr>
                            <tr>
                              <td><strong>_links</strong></td>
                              <td>object</td>
                              <td>Continene los links a recursos asociados.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="keys" className="section-block">
                      <h3 className="block-title">Keys</h3>
                      <p>Para recuperar todas las <code>keys</code> de la partición procedemos a realizar<br />una petición <code>GET</code> a
                        la siguiente URL <code>https://sandbox.tesseract.mx/api/v2/institution/partition/keys</code>.</p>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/partition/keys \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`GET /api/v2/institution/partition/keys HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Content-Type: application/json;charset=UTF-8
  Status: 200 Ok`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>RESPONSE BODY</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`{
      "_content": [
          {
              "id": 1,
              "alias": "18832DE2F6EE26F9A0E5752D558CE342724E204FD03998FC4686EF5A2F6D7E28",
              "algorithm_encryption": "RSA_2048",
              "created_at": "2019-04-05T15:52:22.000+0000",
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/partition/keys/1"
                  }
              }
          },
          {
              "id": 3,
              "alias": "978877C478FB38D6D18FEF0CCE27311D9449DF754F75B5B4D167F672FDC18809",
              "algorithm_encryption": "AES_256",
              "created_at": null,
              "_links": {
                  "self": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/partition/keys/3"
                  },
                  "aes": {
                      "href": "https://sandbox.tesseract.mx/api/v2/institution/aes/978877C478FB38D6D18FEF0CCE27311D9449DF754F75B5B4D167F672FDC18809"
                  }
              }
          }
      ],
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/partition/keys?page=0&size=20"
          }
      },
      "_page": {
          "size": 20,
          "total_elements": 2,
          "total_pages": 1,
          "number": 0
      }
  }`}</SyntaxHighlighter>
                        </div></div>
                      <p>Obteniendo como <code>RESPONSE</code> el siguiente contenido:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>_content</strong></td>
                              <td>array</td>
                              <td>Colección de objectos key.</td>
                            </tr>
                            <tr>
                              <td><strong>_links</strong></td>
                              <td>object</td>
                              <td>Colección de objectos link.</td>
                            </tr>
                            <tr>
                              <td><strong>_page</strong></td>
                              <td>object</td>
                              <td>Objeto con la metainformación de paginación.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="key" className="section-block">
                      <h3 className="block-title">Key</h3>
                      <p>Puede realizar una petición de tipo <code>GET</code>a
                        <code>https://sandbox.tesseract.mx/api/v2/institution/partition/keys/{"{"}key_id{"}"}</code> para recuperar una
                        <code>key</code> específica perteneciente a la partición, donde: </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>key_id</strong></td>
                              <td>number</td>
                              <td>Identificador único de la llave.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`curl -X GET \\
  https://sandbox.tesseract.mx/api/v2/institution/partition/keys/1 \\
  -H 'Accept: application/json' \\
  -H 'Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \\`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`GET /api/v2/institution/partition/keys/1 HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json
  Accept: application/json
  Authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Content-Type: application/json;charset=UTF-8
  Status: 200 Ok
`}</SyntaxHighlighter>
                        </div></div><div className="section-block">
                        <h6>RESPONSE BODY</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`    {
      "id": 1,
      "alias": "18832DE2F6EE26F9A0E5752D558CE342724E204FD03998FC4686EF5A2F6D7E28",
      "algorithm_encryption": "RSA_2048",
      "created_at": "2019-04-05T15:52:22.000+0000",
      "_links": {
          "self": {
              "href": "https://sandbox.tesseract.mx/api/v2/institution/partition/keys/1"
          }
      }
  }`}</SyntaxHighlighter>
                        </div></div>
                      <p>Obteniendo como <code>RESPONSE</code> el siguiente contenido:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>id</strong></td>
                              <td>number</td>
                              <td>Identificador único de la llave.</td>
                            </tr>
                            <tr>
                              <td><strong>alias</strong></td>
                              <td>string</td>
                              <td>Alias único de la llave almacenada en la partición con una longitud de 64 caracteres en
                                formato hexadecimal.</td>
                            </tr>
                            <tr>
                              <td><strong>algorithm_encryption</strong></td>
                              <td>string</td>
                              <td>Algoritmo utilizado. </td>
                            </tr>
                            <tr>
                              <td><strong>created_at</strong></td>
                              <td>string</td>
                              <td>Representación de la fecha de creación de la llave en la zona horaria UTC.</td>
                            </tr>
                            <tr>
                              <td><strong>_link</strong></td>
                              <td>object</td>
                              <td>Contiene los links a recursos asociados.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  <section id="hash-section" className="doc-section">
                    <div id="hash" className="section-block">
                      <h2 className="block-title">HASH</h2>
                      <p>Secure Hash Algorithm (Algoritmos de Hash seguros), también conocidos como SHA, son una familia de funciones criptográficas
                        diseñadas para mantener los datos protegidos. Funciona mediante la transformación de los datos mediante
                        una función hash: un algoritmo que consiste en operaciones bit a bit, adiciones modulares y funciones de
                        compresión.</p>
                      <p>Para utilizar funciones HASH es necesario enviar una petición <code>POST</code> a la siguiente URL
                        <code>https://sandbox.tesseract.mx/api/v2/institution/hash</code>, donde:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>message</strong></td>
                              <td>string</td>
                              <td>Mensaje de la operación</td>
                            </tr>
                            <tr>
                              <td><strong>algorithm</strong></td>
                              <td>string</td>
                              <td>Función HASH soportadas </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X POST \
  http://sandbox.tesseract.mx/api/v2/institution/hash \
  -H 'accept: application/json' \
  -H 'authorization: Basic VmVWdFBwR2NCNnA0OWpndkpZUDQ6SHFaVHVvS3BtR24xcjBmbXpQSDlSYlpiNkdtZG5tb1JxNkJ1NEt6dQ==' \
  -H 'content-type: application/json' \
  -d '{
  "message": "SECRET",
  "algorithm": "SHA512"
  }'`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>CUERPO DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='json' style={tomorrowNight}>{`{
   "message": "SECRET",
   "algorithm": "SHA512"
   }`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Status: 200
  Content-Type: application/json;charset=UTF-8
`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>RESPONSE BODY</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='json' style={tomorrowNight}>{`{
  "message": "FEB6541D492A1D50394CC448E9C4D08AC381C5C90A656B19201BACFDF9462B87A8A5579A47810609C2307DEC92F52C88F218FD3075AFE02629BC5FD01CE734FD"
}`}</SyntaxHighlighter>
                        </div></div>
                      <p>A continuación, las funciones HASH soportadas por la API:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>MD2</strong></td>
                              <td>Algoritmo de Resumen del Mensaje 2.</td>
                            </tr>
                            <tr>
                              <td><strong>MD5</strong></td>
                              <td>Algoritmo de Resumen del Mensaje 5.</td>
                            </tr>
                            <tr>
                              <td><strong>SHA</strong></td>
                              <td>Algoritmo de Hash Seguro.</td>
                            </tr>
                            <tr>
                              <td><strong>SHA224</strong></td>
                              <td>Función HASH soportada.</td>
                            </tr>
                            <tr>
                              <td><strong>SHA256</strong></td>
                              <td>Función HASH soportada.</td>
                            </tr>
                            <tr>
                              <td><strong>SHA384</strong></td>
                              <td>Función HASH soportada.</td>
                            </tr>
                            <tr>
                              <td><strong>SHA512</strong></td>
                              <td>Función HASH soportada.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="callout-block callout-danger">
                        <div className="icon-holder">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </div>
                        <div className="content">
                          <p> Se aconseja preferentemente se haga uso de las
                            siguientes funciones: <code>SHA224</code>, <code>SHA256</code>, <code>SHA384</code> y <code>SHA512</code>.</p>
                        </div>
                      </div>
                    </div>

                  </section>
                  <section id="aes-section" className="doc-section">
                    <div id="aes" className="section-block">
                      <h2 className="block-title">AES</h2>
                      <p>Advanced Encryption Standard (AES), también conocido como Rijndael (pronunciado “Rain Doll” en inglés),
                        es un esquema de cifrado por bloques adoptado como un estándar de cifrado por el gobierno de los Estados
                        Unidos.</p>
                      <p>Para cifrar o descrifrar es necesario enviar una petición <code>POST</code> a la siguiente URL
                        <code>https://sandbox.tesseract.mx/api/v2/institution/aes/{"{"}hexadecimal-label-aes-key{"}"}</code>, donde:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>hexadecimal-label-aes-key</strong></td>
                              <td>string</td>
                              <td>Identificador único la llave AES alamcenada en el HSM, con una longitud de 64 caracteres en
                                formato hexadecimal.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>en donde el <code>body</code> requerido es:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>transformation</strong></td>
                              <td>string</td>
                              <td>Tranformación AES a utilizar. </td>
                            </tr>
                            <tr>
                              <td><strong>mode</strong></td>
                              <td>string</td>
                              <td>Modo de la operación <em>ENCRYPT</em> o <em>DECRYPT</em>.</td>
                            </tr>
                            <tr>
                              <td><strong>message</strong></td>
                              <td>string</td>
                              <td>Mensaje de la operación.</td>
                            </tr>
                            <tr>
                              <td><strong>encode</strong></td>
                              <td>string</td>
                              <td>Codificación de la entrada y salida, valores soportados <em>BASE64</em> y
                                <em>HEXADECIMAL</em>.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  curl -X POST \\
    'https://sandbox.tesseract.mx/api/v2/institution/aes/b1cd78c0d6c6055304126dc7d703adee \\
    -H 'Cache-Control: no-cache' \\
    -H 'Content-Type: application/json' \\
    -d '{
          "transformation": "AES_CBC_PKCS5Padding",
          "mode": "ENCRYPT",
          "message": "secret",
          "encode": "BASE64"
      }'`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  POST /api/v2/institution/aes/b1cd78c0d6c6055304126dc7d703adee HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='json' style={tomorrowNight}>{`  {
      "transformation": "AES_CBC_PKCS5Padding",
      "mode": "ENCRYPT",
      "message": "secret",
      "encode": "BASE64"
  }`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  Status: 200
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>RESPONSE BODY</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='json' style={tomorrowNight}>{`  {
      "message": "AxC2UntpJpK1II8LBMaImYNwBOz6sYZITYAyZfcG4njefg=="
  }`}</SyntaxHighlighter>
                        </div></div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Transformación</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>AES_CBC_NoPadding</strong></td>
                              <td>AES Encryption in CBC Mode (Cipher Block Chaining mode) No Padding.</td>
                            </tr>
                            <tr>
                              <td><strong>AES_CBC_ISO10126Padding</strong></td>
                              <td>AES Encryption in CBC Mode (Cipher Block Chaining mode) ISO 10126 Padding.</td>
                            </tr>
                            <tr>
                              <td><strong>AES_CBC_PKCS5Padding</strong></td>
                              <td>AES Encryption in CBC Mode (Cipher Block Chaining mode) PKCS5 Padding.</td>
                            </tr>
                            <tr>
                              <td><strong>AES_CFB8_NoPadding</strong></td>
                              <td>AES Encryption in CFB8 Mode (Cipher Feedback 8 mode) No Padding.</td>
                            </tr>
                            <tr>
                              <td><strong>AES_CFB128_NoPadding</strong></td>
                              <td>AES Encryption in CFB128 Mode (Cipher Feedback 128 mode) No Padding.</td>
                            </tr>
                            <tr>
                              <td><strong>AES_CTR_NoPadding</strong></td>
                              <td>AES Encryption in CTR Mode (Cipher Counter mode) No Padding.</td>
                            </tr>
                            <tr>
                              <td><strong>AES_ECB_NoPadding</strong></td>
                              <td>AES Encryption in ECB Mode (Cipher Electronic Codebook mode) No Padding.</td>
                            </tr>
                            <tr>
                              <td><strong>AES_GCM_NoPadding</strong></td>
                              <td>AES Encryption in GCM Mode (Cipher Galois/Counter mode) No Padding.</td>
                            </tr>
                            <tr>
                              <td><strong>AES_OFB_NoPadding</strong></td>
                              <td>AES Encryption in OFB Mode (Cipher Output Feedback mode) No Padding.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </section>
                  <section id="rsa-section" className="doc-section">
                    <div id="rsa" className="section-block">
                      <h2 className="block-title">RSA</h2>
                      <p>RSA es un algoritmo de cifrado asimétrico, o de clave pública, que trabaja con dos claves, una pública y
                        una privada. Todo el contenido de texto plano, o contenido sin cifrar que sea hecho con la clave
                        pública, podrá ser decifrado con la clave privada y viceversa. Todo el contenido cifrado con la clave
                        privada podrá ser descifrado con la clave pública.</p>
                      <p>Para cifrar o descifrar es necesario enviar una petición <code>POST</code>a la siguiente URL
                        <code>https://sandbox.tesseract.mx/api/v2/institution/rsa/{"{"}hexadecimal-label-rsa-key{"}"}</code>, donde:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>hexadecimal-label-rsa-key</strong></td>
                              <td>string</td>
                              <td>Identificador único del par de llaves RSA almacenada en el HSM, con una longitud de 64
                                caracteres en formato hexadecimal.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>en donde el <code>body</code> requerido es:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Descripción </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>transformation</strong></td>
                              <td>string</td>
                              <td>Transformación RSA a utilizar.</td>
                            </tr>
                            <tr>
                              <td><strong>mode</strong></td>
                              <td>string</td>
                              <td>Modo de la operación <em>ENCRYPT</em> ó <em>DECRYPT</em></td>
                            </tr>
                            <tr>
                              <td><strong>message</strong></td>
                              <td>string</td>
                              <td>Mensaje de la operación</td>
                            </tr>
                            <tr>
                              <td><strong>encode</strong></td>
                              <td>string</td>
                              <td>Codificación de la entrada y salida, valores soportados <em>BASE64</em> y
                                <em>HEXADECIMAL</em>.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="section-block">
                        <h6>EJEMPLO</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`curl -X POST \\
    'https://sandbox.tesseract.mx/api/v2/institution/rsa/b1cd78c0d6c6055304126dc7d703adee \\
    -H 'Cache-Control: no-cache' \\
    -H 'Content-Type: application/json' \\
    -d '{
          "transformation": "RSA_NONE_NoPadding",
          "mode": "ENCRYPT",
          "message": "secret",
          "encode": "BASE64"
      }'`}</SyntaxHighlighter>
                        </div>
                      </div>
                      <div className="section-block">
                        <h6>ENCABEZADOS DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`POST /api/v2/institution/rsa/b1cd78c0d6c6055304126dc7d703adee HTTP/1.1
  Host: sandbox.tesseract.mx
  Content-Type: application/json`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CUERPO DE LA PETICIÓN</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`  {
      "transformation": "RSA_NONE_NoPadding",
      "mode": "ENCRYPT",
      "message": "secret",
      "encode": "BASE64"
  }`}</SyntaxHighlighter>
                        </div></div>
                      <div className="section-block">
                        <h6>CABECERAS DE LA RESPUESTA</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`Status: 200
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                        </div></div><div className="section-block">
                        <h6>RESPONSE BODY</h6>
                        <div className="tab__container">
                          <ul className="tab__menu">
                            <li className="tab active" data-tab="curl">curl</li>
                          </ul>
                          <SyntaxHighlighter className="tab__pane active curl" language='curl' style={tomorrowNight}>{`Status: 200
  Content-Type: application/json;charset=UTF-8`}</SyntaxHighlighter>
                        </div></div>
                      <p>Tansformaciones RSA soportadas:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Transformación</th>
                              <th>Descripcion</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><strong>RSA_NONE_NoPadding</strong></td>
                              <td>Cifrado RSA sin modo y sin relleno</td>
                            </tr>
                            <tr>
                              <td><strong>RSA_ECB_OAEPWithSHA1AndMGF1Padding</strong></td>
                              <td>RSA Electronic codebook (ECB). El mensaje es separado en bloques y todos estan encriptados
                                de modo independiente, Optimal Asymmetric Encryption Padding (OAEP) es un esquema de relleno,
                                usa un par de oráculos aleatorios G y H para procesar el texto plano antes del cifrado asimétrico,
                                se puede utilizar para construir una transformación de todo o nada.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="doc-sidebar col-md-3 col-12 order-0 d-none d-md-flex">
                <div id="doc-nav" className="doc-nav">
                  <nav id="doc-menu" className="nav doc-menu flex-column sticky">
                    <li className="nav-item">
                      <a className="nav-link selected scrollto" href="#intro-section">Introducción</a>
                    </li>
                    <nav className="nav doc-sub-menu nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#request">Request</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#response">Response</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#links">Links</a>
                      </li>
                      <nav className="nav doc-sub-menu-s nav flex-column">
                        <li className="nav-item">
                          <a className="nav-link scrollto" href="#resource_links">Links de recurso</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link scrollto" href="#metainformation">Metainformación</a>
                        </li>
                      </nav>
                    </nav>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#httpstates-section">Estados HTTP</a>
                    </li>
                    <nav className="nav doc-sub-menu nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#satisfactory">Satisfactorios</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#errors">Errores</a>
                      </li>
                    </nav>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#auth-section">Autenticación</a>
                    </li>
                    <nav className="nav doc-sub-menu nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#registration">Registro</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#stub">Resguardo</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#activation">Activación</a>
                      </li>
                    </nav>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#authr-section">Auth</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#institution-section">Institución</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#license-section">Licencias</a>
                    </li>
                    <nav className="nav doc-sub-menu nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#license">Licencia</a>
                      </li>
                    </nav>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#token-section">Tokens</a>
                    </li>
                    <nav className="nav doc-sub-menu nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#token">Token</a>
                      </li>
                      <nav className="nav doc-sub-menu-s nav flex-column">
                        <li className="nav-item">
                          <a className="nav-link scrollto" href="#algorithm-parameters">Algorithm Parameters</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link scrollto" href="#challenge-format">Challenge Format</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link scrollto" href="#response-format">Response Format</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link scrollto" href="#data">Data</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link scrollto" href="#pin-policy">PIN Policy</a>
                        </li>
                      </nav>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#create-token">Crear un nuevo Token</a>
                      </li>
                      <nav className="nav doc-sub-menu-s nav flex-column">
                        <li className="nav-item">
                          <a className="nav-link scrollto" href="#advance-parameters">Parámetros Avanzados</a>
                        </li>
                      </nav>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#enrollment-string">Enrollment String</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#act-code">Act Code</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#challenge">Challenge</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#validate-token">Validate Token Result</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#token-type">Token Type</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#token-status">Token Status</a>
                      </li>
                    </nav>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#apps-section">Aplicaciones</a>
                    </li>
                    <nav className="nav doc-sub-menu nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#get-app">Recuperar una Aplicación</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#create-app">Crear una nueva Aplicación</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#update-app">Actualizar una Aplicación</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#delete-app">Eliminar una Aplicación</a>
                      </li>
                    </nav>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#partition-section">Partición</a>
                    </li>
                    <nav className="nav doc-sub-menu nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link scrollto" href="#keys">Keys</a>
                      </li>
                      <nav className="nav doc-sub-menu-s nav flex-column">
                        <li className="nav-item">
                          <a className="nav-link scrollto" href="#key">Key</a>
                        </li>
                      </nav>
                    </nav>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#hash-section">HASH</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#aes-section">AES</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scrollto" href="#rsa-section">RSA</a>
                    </li>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default <Developers />
