import React from 'react'
import Icon1 from '../../images/lowcost.svg'
import Icon2 from '../../images/cloud.svg'
import Icon3 from '../../images/law.svg'
import {FeaturesContainer, FeaturesWrapper, FeaturesCard, FeaturesIcon, FeaturesH2, FeaturesP} from './FeaturesElements'

const Features = () => {
  return (
    <FeaturesContainer id='features'>
      <FeaturesWrapper>
        <FeaturesCard>
          <FeaturesIcon alt="" src={Icon1}/>
          <FeaturesH2>Bajo costo</FeaturesH2>
          <FeaturesP>Brindamos soluciones cómodas y accesibles para su negocio </FeaturesP>
        </FeaturesCard>
        <FeaturesCard>
        <FeaturesIcon alt="" src={Icon2}/>
        <FeaturesH2>Soluciones en la nube </FeaturesH2>
          <FeaturesP>Soluciones en la nube, implementación cómoda a tus operaciones</FeaturesP>
        </FeaturesCard>
        <FeaturesCard>
          <FeaturesIcon alt="" src={Icon3}/>
          <FeaturesH2>Regulaciones </FeaturesH2>
          <FeaturesP>Todos nuestros productos cumplen con las normas de la CNBV, BANXICO, SHCP y/o INAI </FeaturesP>
        </FeaturesCard>
      </FeaturesWrapper>
    </FeaturesContainer>
  )
}

export default Features
