import styled from "styled-components";
export default function TextArea({ type, placeholder, id, required }) {
  return <StyledTextArea type={type} placeholder={placeholder} id={id} required={required} />;
}

const StyledTextArea = styled.textarea`
  background: rgba(255, 255, 255);
  box-shadow: 0 1px 2px 0 #039F1C;
  width: 100%;
  height: 10rem;
  padding: 1rem;
  border: none;
  outline: none;
  color: #302D47;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
  margin-left: auto;
    margin-right: auto;
    display: block;
  &:focus {
    display: inline-block;
    box-shadow: 0 0 0 0.2rem #039F1C;
    backdrop-filter: blur(12rem);
    border-radius: 1rem;
  }
  &::placeholder {
    color: #302D47;
    font-weight: 100;
    font-size: 1rem;
  }

`;
