import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1D192B;
  width: 100%;
  @media screen and (max-width: 768px) {
    
  }

  @media screen and (max-width: 420px) {
    
  }
 `
 export const FormH1 = styled.h1`
  font-size: 2.5rem;
  color: #4FBA04;
  margin-bottom:2%;
  margin-top:3%;
  
`
export const FormP = styled.p`
  color: #fff;
  font-size: 24px;
  text-align:center;
  @media screen and (max-width: 768px) {
    margin-left:5%;
    margin-right:5%
  }
  
`
export const InputContainer = styled.div`
  width:70%;
  margin-top:20px;
  display: grid;
  grid-template-columns: 50% 50%; 
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0px;
  @media screen and (max-width: 768px) {
    display: block;
    width:80%;
  }

`;
export const FormBtn = styled.div`
 display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

export const FormHiringBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width:90%
  margin-bottom:10%;
`
export const FormBtnLink = styled(LinkR)`
  border-radius: 10px;
  background: #039F1C;
  white-space: nowrap;
  padding: 8px 22px;
  color: #010606;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #83C77F;
    color: #010606;
  }
`
export const Map = styled.div` 
width: 100%;

`

export const FormText = styled.div` 
width: 100%;
  justify-content: center;
  align-items: center;
@media screen and (max-width: 768px) {
  margin-top: 5%;
  align-items:center;
}
`

export const FormP2 = styled.p`
  font-size: 1rem;
  color: #fff;
  text-align:center;
  
  @media screen and (max-width: 420px) {
    margin:0px;
  }
`
export const Email = styled.p`
  font-size: 1rem;
  color: #C1C5D0;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    text-align:center;
  }
`