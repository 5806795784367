import styled from 'styled-components'
export const ClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin-top: 30px;
  margin-bottom: 60px;
 `
 export const ClientsWrapper = styled.div`
 position: relative;
 margin: 0 auto;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 align-items: center;
 grid-gap: 16px;

 @media screen and (max-width: 786px) {
   grid-template-columns: 1fr;
   padding: 0 20px;
}
`

export const MarqueeIcon = styled.img`
  width:8%;
  height:8%;
  @media screen and (max-width: 786px) {
    width:40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 10% 
    display: block;
  }
`  
export const ClientsH1 = styled.h1`
  font-size: 2.5rem;
  color: #4FBA04;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`
export const ClientsCard = styled.div`
  display: flex;
  width:250px;
  height:150px;
  border-radius: 7px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }
`
export const ClientsIcon = styled.img`
  width:38%;
  height:38%;
  margin-bottom: auto;
  flex: 30%;
  margin-top:auto;
  margin-left:2%
`

export const ClientsH2 = styled.span`
  font-size: 16px;
  color: #FFF;
  font-weight: bold;
  flex: 70%;
  margin-top:auto;
  margin-bottom:auto;
  text-align:center;
`
export const ClientsF = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-top:5px;
`
export const ReviewClients = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  text-align:center;
  width:70%;
  margin-top:3%;
  font-size:14px;
  color: #5F5F5F;
`

export const ClientsIconsMB = styled.div`
  display:none;
@media screen and (max-width: 786px) {
  display:block;
  width: 100%;
  margin-bottom:10%;
}
`
export const ClientsIcons = styled.div`
display:contents;
@media screen and (max-width: 786px) {
  display:none;
}
`
export const Left = styled.div`
width: 50%; 
float:left;
`
export const Right = styled.div`
width: 50%; 
float:right;
`
