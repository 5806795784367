import styled from 'styled-components';

export const MediaContainer = styled.div`
    width:100%;
    background: #fff;
    align-items: center;
    padding-top: 1%;
`

export const Container = styled.div`
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
    align-items: center;
    padding-bottom:1.5%;
    @media screen and (max-width: 786px) {
      grid-template-columns: 100%;
   }
`
export const Left = styled.div`
width:100%;
`
export const Right = styled.div`
width:100%;
`
export const Selector = styled.div`
background: #394958;
width:70%;
height: 5rem;
margin-bottom: 3%;
margin-left: auto;
margin-right: auto;
border-radius: 15px;
display: flex;
align-items: center;
  justify-content: center;
`

export const SelectorImage = styled.img`
width: 40%;
`

export const SelectedImg = styled.img`
    display: block;
    margin: auto;
    height: 10%;
    width 80%
    `

export const MentionsH1 = styled.h1`
    font-size: 2.5rem;
    color: #4FBA04;
    text-align: center;
    margin-top:2%;
    margin-bottom:3%;
  `
export const Title = styled.h1`
  font-size: 1rem;
  color: #282C47;
  text-align: center;
`
export const ImgContainer = styled.div`
    height: 100%;
    align-items:center;
    text-align: center;
`