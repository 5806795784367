import { React, useState } from 'react'
import {
    ProductsContainer, ProductsH1, ProductsWrapper, CardC,
    CardImageContainer, CardImage, CardBody, CardTitle, CardDescription,
    CardButton, ProductContainer, StyledPopup, Background, ModalContainer, Close
} from './ProductsCardsElements'
import Icon from '../../images/productsProv/biom.svg'
import Icon1 from '../../images/productsProv/bov.svg'
import Icon2 from '../../images/productsProv/cyf.svg'
import Icon3 from '../../images/productsProv/hard.svg'
import Icon4 from '../../images/productsProv/hsm.svg'
import Icon5 from '../../images/productsProv/onbo.svg'
import Icon6 from '../../images/productsProv/pcf.svg'
import Icon7 from '../../images/productsProv/sign.svg'
import Icon8 from '../../images/productsProv/sp.svg'
import Icon9 from '../../images/productsProv/st.svg'
import OnboardingRV from './OnboardingRV'
import OnBoarding from './Onboarding/Onboarding'
import BiometricEncryption from './BiometricEncryption/BiometricEncryption'
import BiometricEncryptionRV from './BiometricEncryptionRV'
import CaaSPro from './CaaSPro/CaaSPro'
import CaaSProRV from './CaasProRV'
import CryptoVault from './CryptoVault/CryptoVault'
import CryptoVaultRV from './CryptoVaultRV'
import HardwareCloud from './HardwareCloud/HardwareCloud'
import HardwareCloudRV from './HardwareCloudRV'
import InformationEncryption from './InformationEncryption/InformationEncryption'
import InformationEncryptionRV from './InformationEncryptionRV'
import PC2fa from './PC2fa/PC2fa'
import PC2faRV from './PC2faRV'
import ProfessionalServicesRV from './ProfessionalServicesRV'
import ProfessionalServices from './ProfessionalServices/ProfessionalServices'
import Signature from './Signature/Signature'
import SignatureRV from './SignatureRV'
import SoftToken from './SoftToken/SoftToken'
import SoftTokenRV from './SoftTokenRV'


function Card(props) {
    return (
        <CardC>
            <CardImageContainer>
                <CardImage src={props.img}alt=""/>
            </CardImageContainer>
            <CardBody>
                <CardTitle>{props.title}</CardTitle>
                <CardDescription>{props.description}</CardDescription>
                <CardButton onClick={props.handleClick}>Ver Detalle</CardButton>
            </CardBody>
        </CardC>
    );
}
const ProductsSection = () => {
    const [isShown, setIsShown] = useState(false);
    const [modal, setModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [isShownBE, setIsShownBE] = useState(false);
    const [openBE, setOpenBE] = useState(false);
    const [isShownIE, setIsShownIE] = useState(false);
    const [openIE, setOpenIE] = useState(false);
    const [isShownCP, setIsShownCP] = useState(false);
    const [openCP, setOpenCP] = useState(false);
    const [isShownCV, setIsShownCV] = useState(false);
    const [openCV, setOpenCV] = useState(false);
    const [isShownHC, setIsShownHC] = useState(false);
    const [openHC, setOpenHC] = useState(false);
    const [isShownPC, setIsShownPC] = useState(false);
    const [openPC, setOpenPC] = useState(false);
    const [isShownPS, setIsShownPS] = useState(false);
    const [openPS, setOpenPS] = useState(false);
    const [isShownS, setIsShownS] = useState(false);
    const [openS, setOpenS] = useState(false);
    const [isShownST, setIsShownST] = useState(false);
    const [openST, setOpenST] = useState(false);

    const handleClickOB = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpen(current => !current);
        } else {
            setOpen(false);
        }
        setIsShown(current => !current);
        setOpen(current => !current);
        setModal(current => !current);
    };
    const handleClickBE = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpenBE(current => !current);
        } else {
            setOpenBE(false);
        }
        setIsShownBE(current => !current);
        setOpenBE(current => !current);
        setModal(current => !current);
    };
    const handleClickCP = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpenCP(current => !current);
        } else {
            setOpenCP(false);
        }
        setIsShownCP(current => !current);
        setOpenCP(current => !current);
        setModal(current => !current);
    };
    const handleClickCV = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpenCV(current => !current);
        } else {
            setOpenCV(false);
        }
        setIsShownCV(current => !current);
        setOpenCV(current => !current);
        setModal(current => !current);
    };
    const handleClickHC = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpenHC(current => !current);
        } else {
            setOpenHC(false);
        }
        setIsShownHC(current => !current);
        setOpenHC(current => !current);
        setModal(current => !current);
    };
    const handleClickPC = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpenPC(current => !current);
        } else {
            setOpenPC(false);
        }
        setIsShownPC(current => !current);
        setOpenPC(current => !current);
        setModal(current => !current);
    };
    const handleClickPS = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpenPS(current => !current);
        } else {
            setOpenPS(false);
        }
        setIsShownPS(current => !current);
        setOpenPS(current => !current);
        setModal(current => !current);
    };
    const handleClickS = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpenS(current => !current);
        } else {
            setOpenS(false);
        }
        setIsShownS(current => !current);
        setOpenS(current => !current);
        setModal(current => !current);
    };
    const handleClickST = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpenST(current => !current);
        } else {
            setOpenST(false);
        }
        setIsShownST(current => !current);
        setOpenST(current => !current);
        setModal(current => !current);
    };
    const handleClickIE = event => {
        const screenAvailWidth = window.screen.availWidth;
        if (screenAvailWidth < 720) {
            setOpenIE(current => !current);
        } else {
            setOpenIE(false);
        }
        setIsShownIE(current => !current);
        setOpenIE(current => !current);
        setModal(current => !current);
    };
    return (
        <ProductsContainer id='products'>
            <Background modal={modal}></Background>
            <ProductsH1>Nuestras Soluciones</ProductsH1>
            <ProductsWrapper>
                <Card
                    img={Icon}
                    title="Tesseract Cifrado Biométrico"
                    description="Recolección de datos biométricos que pueden ser utilizados para identificar digitalmente a una persona."
                    handleClick={handleClickBE}
                />
                {isShownBE && (
                    <ProductContainer isShown={isShownBE}>
                        <BiometricEncryptionRV />
                    </ProductContainer>
                )}
                <StyledPopup open={openBE} closeOnDocumentClick={false} position="right center">
                    <ModalContainer >
                        <Close onClick={() => { setOpenBE(false); setModal(false) }}>
                            &times;
                        </Close>
                        <BiometricEncryption />
                    </ModalContainer>
                </StyledPopup>


                <Card
                    img={Icon1}
                    title="Bóveda Criptográfica HSM"
                    description="Particiones independientes y de uso exclusivo de la institución contratante a través del uso de roles de seguridad."
                    handleClick={handleClickCV}
                />
                {isShownCV && (
                    <ProductContainer isShown={isShownCV}>
                        <CryptoVaultRV />
                    </ProductContainer>
                )}
                <StyledPopup open={openCV} closeOnDocumentClick={false} position="right center">
                    <ModalContainer >
                        <Close onClick={() => { setOpenCV(false); setModal(false) }}>
                            &times;
                        </Close>
                        <CryptoVault />
                    </ModalContainer>
                </StyledPopup>
                <Card
                    img={Icon2}
                    title="Tesseract Cifrado de Información"
                    description="Protege tu información con el cifrado en ciberseguridad, convierte datos de un formato legible a un formato codificado."
                    handleClick={handleClickIE}
                />
                {isShownIE && (
                    <ProductContainer isShown={isShownIE}>
                        <InformationEncryptionRV />
                    </ProductContainer>
                )}
                <StyledPopup open={openIE} closeOnDocumentClick={false} position="right center">
                    <ModalContainer>
                        <Close onClick={() => { setOpenIE(false); setModal(false) }}>
                            &times;
                        </Close>
                        <InformationEncryption />
                    </ModalContainer>
                </StyledPopup>
                <Card
                    img={Icon9}
                    title="Tesseract Soft-Token"
                    description="Mecanismo de seguridad en dos pasos para validación de usuarios."
                    handleClick={handleClickST}
                />
                {isShownST && (
                    <ProductContainer isShown={isShownST}>
                        <SoftTokenRV />
                    </ProductContainer>
                )}
                <StyledPopup open={openST} closeOnDocumentClick={false} position="right center">
                    <ModalContainer>
                        <Close onClick={() => { setOpenST(false); setModal(false) }}>
                            &times;
                        </Close>
                        <SoftToken />
                    </ModalContainer>
                </StyledPopup>

                <Card
                    img={Icon4}
                    title="Tesseract CaaS Pro"
                    description="Soluciones simples y accesibles que permiten un cifrado de datos en una transmisión a gran velocidad."
                    handleClick={handleClickCP}
                />
                {isShownCP && (
                    <ProductContainer isShown={isShownCP}>
                        <CaaSProRV />
                    </ProductContainer>
                )}
                <StyledPopup open={openCP} closeOnDocumentClick={false} position="right center">
                    <ModalContainer >
                        <Close onClick={() => { setOpenCP(false); setModal(false) }}>
                            &times;
                        </Close>
                        <CaaSPro />
                    </ModalContainer>
                </StyledPopup>
                <Card
                    img={Icon5}
                    title="Tesseract OnBoarding"
                    description="Digitaliza el proceso de alta con la tecnología biométrica líder del sector."
                    handleClick={handleClickOB}
                />
                {isShown && (
                    <ProductContainer isShown={isShown}>
                        <OnboardingRV />
                    </ProductContainer>
                )}
                <StyledPopup open={open} closeOnDocumentClick={false} position="right center">
                    <ModalContainer>
                        <Close onClick={() => { setOpen(false); setModal(false) }}>
                            &times;
                        </Close>
                        <OnBoarding />
                    </ModalContainer>
                </StyledPopup>
                <Card
                    img={Icon6}
                    title="Tesseract PC 2FA"
                    description="Autenticación de usuarios en diferentes niveles de gestión de accesos, fácil y seguro."
                    handleClick={handleClickPC}
                />
                {isShownPC && (
                    <ProductContainer isShown={isShownPC}>
                        <PC2faRV />
                    </ProductContainer>
                )}
                <StyledPopup open={openPC} closeOnDocumentClick={false} position="right center">
                    <ModalContainer >
                        <Close onClick={() => { setOpenPC(false); setModal(false) }}>
                            &times;
                        </Close>
                        <PC2fa />
                    </ModalContainer>
                </StyledPopup>
                <Card
                    img={Icon7}
                    title="Tesseract Firma"
                    description="Solución práctica, rápida y confiable para realizar firmas de manera digital en cualquier parte."
                    handleClick={handleClickS}
                />
                {isShownS && (
                    <ProductContainer isShown={isShownS}>
                        <SignatureRV />
                    </ProductContainer>
                )}
                <StyledPopup open={openS} closeOnDocumentClick={false} position="right center">
                    <ModalContainer>
                        <Close onClick={() => { setOpenS(false); setModal(false) }}>
                            &times;
                        </Close>
                        <Signature />
                    </ModalContainer>
                </StyledPopup>

                <Card
                    img={Icon8}
                    title="Servicios Profesionales"
                    handleClick={handleClickPS}
                />
                {isShownPS && (
                    <ProductContainer isShown={isShownPS}>
                        <ProfessionalServicesRV />
                    </ProductContainer>
                )}
                <StyledPopup open={openPS} closeOnDocumentClick={false} position="right center">
                    <ModalContainer style={{ width: "100%" }}>
                        <Close onClick={() => { setOpenPS(false); setModal(false) }} style={{ right: "-3%" }}>
                            &times;
                        </Close>
                        <ProfessionalServices />
                    </ModalContainer>
                </StyledPopup>
                <Card
                    img={Icon3}
                    title="Tesseract Hardware Cloud"
                    handleClick={handleClickHC}
                />
                {isShownHC && (
                    <ProductContainer isShown={isShownHC}>
                        <HardwareCloudRV />
                    </ProductContainer>
                )}
                <StyledPopup open={openHC} closeOnDocumentClick={false} position="right center">
                    <ModalContainer style={{ width: "100%" }}>
                        <Close onClick={() => { setOpenHC(false); setModal(false) }} style={{ right: "-3%" }}>
                            &times;
                        </Close>
                        <HardwareCloud />
                    </ModalContainer>
                </StyledPopup>


            </ProductsWrapper>

        </ProductsContainer>
    )
}

export default ProductsSection