import image from "../../images/mapa.svg"
export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: true,
  lighttextDesc: true,
  topLine: 'Innovamos y evolucionamos',
  description: 'Brindamos soluciones en cualquier parte del mundo. Somos una entidad comprometida en el cumplimiento y normativa de instituciones regulatorias, contamos con distintas certificaciones que avalan nuestro compromiso con tu negocio. ',
  description1: 'Cumplimos las disposiciones de carácter general aplicables a las actividades de las sociedades cooperativas de ahorro y préstamo autorizada por la CNBV.',
  description2: 'Cumpliendo con las Disposiciones de carácter general del Artículo 58 de la Ley para Regular las instituciones de Tecnología Financiera a través del DOF.',
  imgStart: false,
  img: image,
  alt: 'map',
  dark: true,
  primary: true,
  darkText: true
};

export const homeObjTwo = {
  id: 'features',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  dark: false,
  primary: false,
  darkText: true
};

