import React, { useState } from "react";
import Image from '../../images/media/f2.svg'
import Image2 from '../../images/media/f1.svg'
import Image3 from '../../images/media/f3.svg'
import Image4 from '../../images/media/f6.svg'
import Image5 from '../../images/media/f5.svg'
import { MediaContainer, Container, Left, Right, SelectedImg, Title, MentionsH1, ImgContainer, Selector, SelectorImage } from './MentionsElements'
const images = [
  {
    index: 0,
    image: 'https://cdn.forbes.com.mx/2018/02/Tesseract.jpg',
    title: 'Tesseract, tecnología militar contra ciberataques',
    url: 'https://www.forbes.com.mx/tesseract-tecnologia-militar-contra-ciberataques/',
    logo: Image
  },
  {
    index: 1,
    image: 'https://cdn.forbes.com.mx/2015/07/jes%C3%BAs-640x400.jpg',
    title: 'Éstas son las 9 empresas de Fintech de Startupbootcamp en Latam',
    url: 'https://www.forbes.com.mx/estas-son-las-9-empresas-de-fintech-de-startupbootcamp-en-latam/',
    logo: Image
  },
  {
    index: 2,
    image: 'https://img.gruporeforma.com/imagenes/960x640/4/606/3605016.jpg',
    title: 'Llega semillero de Fintechs a México',
    url: 'https://www.reforma.com/aplicacioneslibre/preacceso/articulo/default.aspx?__rval=1&urlredirect=https://www.reforma.com/llega-semillerode-fintechs-a-mexico/ar1151779?referer=--7d616165662f3a3a6262623b727a7a7279703b767a783a--',
    logo: Image2
  },
  {
    index: 3,
    image: 'https://www.eleconomista.com.mx/__export/1507911250176/sites/eleconomista/img/historico/fintech_1.jpg_393767599.jpg',
    title: 'Las startups que quieren hackear el sistema financiero',
    url: 'https://www.eleconomista.com.mx/tecnologia/Las-startups-que-quieren-hackear-el-sistema-financiero-20170804-0038.html',
    logo: Image3
  },
  {
    index: 4,
    image: 'https://cdn2.excelsior.com.mx/media/styles/image800x600/public/pictures/2017/08/11/1729098.jpg',
    title: 'Las FinTech no temen crecer',
    url: 'https://www.excelsior.com.mx/hacker/2017/08/11/1180999#view-',
    logo: Image4
  },
  {
    index: 5,
    image: 'https://www.elfinanciero.com.mx/resizer/_xuCT1GyVOhAUR6WWrfc2xQWORI=/800x0/filters:format(png):quality(70)/cloudfront-us-east-1.images.arcpublishing.com/elfinanciero/IHLF4FRWCUZVHLMNC5IM66OGTA.png',
    title: 'Las Fintech que más crecen no compiten con los bancos, trabajan con ellos',
    url: 'https://www.elfinanciero.com.mx/economia/las-fintech-que-mas-crecen-no-compiten-con-los-bancos-trabajan-con-ellos/',
    logo: Image5
  },
];
const MediaMentions = () => {
  const [selectedImg, setSelectedImg] = useState(images[0]);

  return (
    <MediaContainer id='mentions'>
      <MentionsH1>Algunas menciones en los medios</MentionsH1>
      <Container>
        <Left onClick={() => window.open(selectedImg.url)}>
          <SelectedImg src={selectedImg.image} alt="Selected" />
          <Title>{selectedImg.title}</Title>
        </Left>
        <Right>
          <ImgContainer>
            {images.map(item => <Selector key={item.index} 
              style={{ background: selectedImg === item ? "#3887FE" : "" }}
              onClick={() => setSelectedImg(item)}><SelectorImage src={item.logo} alt=""/>
              </Selector>)}
          </ImgContainer>
        </Right>
      </Container>
    </MediaContainer>

  )
}

export default MediaMentions


