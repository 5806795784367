import './App.css';
import {BrowserRouter as Router, Routes, Route} from  'react-router-dom'
import Home from './pages';
import Privacy from './pages/privacyPolicy'
import Developers from './pages/developers'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={Home} exact />
        <Route path='/privacy-policy' element={Privacy} exact />
        <Route path='/api-doc' element={Developers} exact />
      </Routes>
    </Router>
  );
}

export default App;
