import React, {useState} from 'react'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Sidebar from '../components/SideBar';
import PrivacyPolicy from '../components/PrivacyPolicy'

const Privacy = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <PrivacyPolicy/>
      <Footer />
    </>
  )
}

export default <Privacy/>
