import React, { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { Nav, ImageBg, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavLinks, NavItem, 
  NavItemW, NavBtn, NavBtnLink, NavLinksExt } from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll';
import Image from '../../images/t-icon.svg'
import { FaWhatsapp } from 'react-icons/fa'

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to='/' onClick={toggleHome}><ImageBg src={Image} alt=""/> tesseract </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks to='features'
                  smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Acerca</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='products'
                  smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Productos</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinksExt href='https://tesseractmx.zendesk.com/hc/es' target='_blank'>Soporte</NavLinksExt>
              </NavItem>
              <NavItem>
                <NavLinks to='form'
                  smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Contactanos</NavLinks>
              </NavItem>
              <NavItemW>
                <NavLinksExt href='https://api.whatsapp.com/send/?phone=5578269422&text&app_absent=0' target='_blank'>
                  <FaWhatsapp />
                </NavLinksExt>
              </NavItemW>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to='/api-doc'>Developers</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
