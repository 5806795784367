import React from 'react'
import alquimia from '../../images/clientes/alquimia-logo.png'
import asp from '../../images/clientes/asp-logo.png'
import aspel from '../../images/clientes/aspel-logo.svg'
import autofin from '../../images/clientes/autofin-logo.png'
import avanthya from '../../images/clientes/avanthya-logo.png'
import bancrea from '../../images/clientes/bancrea-logo.png'
import cacao from '../../images/clientes/cacao-logo.png'
import cat from '../../images/clientes/cat-logo.jpg'
import cmv from '../../images/clientes/cmv_logo.jpg'
import conecta from '../../images/clientes/conecta-logo.png'
import donde from '../../images/clientes/donde-logo.png'
import epica from '../../images/clientes/epica-logo.png'
import evolve from '../../images/clientes/evolve-logo.svg'
import fincomun from '../../images/clientes/fincomun-logo.png'
import ictineo from '../../images/clientes/ictineo-logo.png'
import kubo from '../../images/clientes/kubo-logo.png'
import reforma from '../../images/clientes/reforma-logo.png'
import smg from '../../images/clientes/smg-logo.png'
import sv from '../../images/clientes/sv_logo.svg'
import treevolt from '../../images/clientes/treevolt-logo.webp'
import Blu from '../../images/socios/logo-blu.png'
import Marquee from "react-easy-marquee"
import {
  ClientsContainer, ClientsH1, MarqueeIcon, ClientsCard, ClientsIcon, ClientsH2, ClientsF,
  ClientsWrapper, ReviewClients, ClientsIconsMB, ClientsIcons, Left, Right

} from './ClientElements'

import { useState } from 'react';
const Clients = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);

  const handleMouseOver1 = () => {
    setIsHovering(true);
  };

  const handleMouseOut1 = () => {
    setIsHovering(false);
  };

  const handleMouseOver2 = () => {
    setIsHovering2(true);
  };

  const handleMouseOut2 = () => {
    setIsHovering2(false);
  };
  const handleMouseOver3 = () => {
    setIsHovering3(true);
  };

  const handleMouseOut3 = () => {
    setIsHovering3(false);
  };
  return (
    <ClientsContainer id='clients'>
      <ClientsH1>Nuestros clientes</ClientsH1>
      <ClientsIconsMB>
        <Left>
          <Marquee duration={30000} height="450px" pauseOnHover={true} axis="Y" >
            <MarqueeIcon alt="alquimia" src={alquimia} style={{ width: "50px" }} />
            <MarqueeIcon alt ="asp" src={asp} />
            <MarqueeIcon alt="aspel" src={aspel} />
            <MarqueeIcon alt="autofin" src={autofin} />
            <MarqueeIcon alt="avanthya" src={avanthya} />
            <MarqueeIcon alt="bancrea" src={bancrea} />
            <MarqueeIcon alt="cacao" src={cacao} />
            <MarqueeIcon alt="smg" src={smg} />
            <MarqueeIcon alt="cat" src={cat} style={{ width: "50px" }} />
            <MarqueeIcon alt="cmv" src={cmv} />
            
          </Marquee>
        </Left>
        <Right>
          <Marquee duration={30000} height="450px" pauseOnHover={true} axis="Y">
            <MarqueeIcon alt="blu" src={Blu} />
            <MarqueeIcon alt="donde" src={donde} />
            <MarqueeIcon alt="epica" src={epica} />
            <MarqueeIcon alt="evolve" src={evolve} />
            <MarqueeIcon alt="fincomun" src={fincomun} />
            <MarqueeIcon alt="ictineo" src={ictineo} />
            <MarqueeIcon alt="kubo" src={kubo} />
            <MarqueeIcon alt="reforma" src={reforma} />
            <MarqueeIcon alt="sv" src={sv} />
            <MarqueeIcon alt="treevolt" src={treevolt} />
          </Marquee>
        </Right>
      </ClientsIconsMB>
      <ClientsIcons>
        <Marquee duration={30000} height="125px" width="90%" pauseOnHover={true}>
          <MarqueeIcon alt="alquimia" src={alquimia} style={{ width: "50px" }} />
          <MarqueeIcon alt ="asp"src={asp} />
          <MarqueeIcon alt="aspel"src={aspel} />
          <MarqueeIcon alt="autofin"src={autofin} />
          <MarqueeIcon alt="avanthya"src={avanthya} />
          <MarqueeIcon alt="bancrea"src={bancrea} />
          <MarqueeIcon alt="cacao"src={cmv} />
          
        </Marquee>
        <Marquee duration={30000} height="125px" width="90%" reverse={true} pauseOnHover={true}>
          <MarqueeIcon alt="blu"src={Blu} />
          <MarqueeIcon alt="cacao"src={cacao} />
          <MarqueeIcon alt="smg"src={smg} />
          <MarqueeIcon alt="cat"src={cat} style={{ width: "50px" }} />
          <MarqueeIcon alt="donde"src={donde} />
          <MarqueeIcon alt="epica"src={epica} />
          <MarqueeIcon alt="evolve"src={evolve} />
        </Marquee>
        <Marquee duration={30000} height="125px" width="90%" pauseOnHover={true}>
          <MarqueeIcon alt="fincomun" src={fincomun} />
          <MarqueeIcon alt="ictineo"src={ictineo} />
          <MarqueeIcon alt="kubo" src={kubo} />
          <MarqueeIcon alt="reforma"src={reforma} />
          <MarqueeIcon alt="sv"src={sv} />
          <MarqueeIcon alt="treevolt"src={treevolt} />
        </Marquee>
      </ClientsIcons>
      <ClientsH1 style={{ textAlign: "center" }}>Lo que opinan algunos de nuestros clientes</ClientsH1>
      <ClientsWrapper>
        <ClientsCard onMouseOver={handleMouseOver1} onMouseOut={handleMouseOut1} style={{ backgroundColor: "#fff" }}>
          <ClientsIcon src={alquimia} alt="alquimia" />
          <ClientsH2 style={{ color: "#00135A" }}>Antonio Álvarez<ClientsF style={{ color: "#00E7A2" }}>Subdirector de Desarrollo TI de:</ClientsF>Alquimia Pay</ClientsH2>
        </ClientsCard>
        <ClientsCard onMouseOver={handleMouseOver2} onMouseOut={handleMouseOut2} style={{ backgroundColor: "#000" }}>
          <ClientsIcon src={Blu} style={{ height: "30%" }}alt="blu"/>
          <ClientsH2 style={{ color: "#3399FF" }}>Jorge Baltazar<ClientsF style={{ color: "#A7A9AC" }}>Director de <br></br>sistemas de:</ClientsF>Blu</ClientsH2>
        </ClientsCard>
        <ClientsCard onMouseOver={handleMouseOver3} onMouseOut={handleMouseOut3} style={{ backgroundColor: "#DA3F57" }} >
          <ClientsIcon src={conecta} style={{ width: "50%", height:"auto" }} alt="conecta"/>
          <ClientsH2 style={{ color: "#F9CB2D" }}>Daniel Mendieta<ClientsF style={{ color: "#fff" }}>Co-Fundador de:</ClientsF>Conecta</ClientsH2>
        </ClientsCard>
      </ClientsWrapper>
      {isHovering && (

        <ReviewClients>"Tesseract es la solución perfecta en cuanto a seguridad informática se refiere, su equipo experto ha solventado cada incidente que ha ocurrido con sus amplias tecnologías que cubren facilmente las necesidades del mercado de la ciberseguridad"</ReviewClients>
      )}
      {isHovering2 && (

        <ReviewClients>"Necesitabamos mantener seguras nuestras aplicaciones web y nuestros accesos a diferentes API de nuestra plataforma.
          Con Tesseract podemos implementar una herramienta para autenticar cada usuario y cada peticion de información en nuestra plataforma.
          Tener el proceso de autenticación del lado de un tercero como Tesseract fortalece nuestra área de seguridad con poco esfuerzo."</ReviewClients>
      )}
      {isHovering3 && (

        <ReviewClients>"Tesseract ha sido una un socio fundamental para el desarrollo de nuestra startup al brindarnos tecnología de autenticación y cifrado de forma muy accesible, dinámica y segura. La integración fue muy sencilla y contamos con el apoyo del equipo de Tesseract en todo momento y por medio de su muy útil sandbox. "</ReviewClients>
      )}
    </ClientsContainer>
  )
}

export default Clients