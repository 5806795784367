import React, {useState} from 'react'
import Footer from '../components/Footer';
import Hero from '../components/Hero'
import Info from '../components/Info'
import {homeObjOne} from '../components/Info/Data';
import Navbar from '../components/Navbar'
import Sidebar from '../components/SideBar'
import Clients from '../components/Clients';
import Partners from '../components/Partners';
import Form from '../components/Form';
import Features from '../components/Features';
import MediaMentions from '../components/MediaMentions';
import ProductsSection from '../components/ProductsCards';
const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <Hero />
      <Features />
      <ProductsSection/>
      <Info {...homeObjOne}/>
      <Clients />
      <Partners />
      <MediaMentions />
      <Form />
      <Footer />
    </>
  )
}

export default <Home/>
