import styled from 'styled-components'

export const FeaturesContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  margin-top: -150px;
 `

export const FeaturesWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
  }

    @media screen and (max-width: 480px) {
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      grid-gap: 16px;
      padding: 0 50px;
  }
`
export const FeaturesCard = styled.div`
  background: transparent;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding:  30px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }
  
`
export const FeaturesIcon = styled.img`
  height: 140px;
  width: 140px;
  margin-bottom: 10px;
  margin-top: 60px;

`
export const FeaturesH1 = styled.h1`
  font-size: 2.5rem;
  color: #282C47;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`
export const FeaturesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
  color: #282C47;
`


export const FeaturesP = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #282C47;
`
