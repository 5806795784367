import styled from 'styled-components'

export const ModelContainer = styled.div`
display: grid;
grid-auto-columns: minmax(50%, 50%);
grid-template-areas: 'col1 col2';
background-color:#fff;
border-radius: 15px;
 `
 export const InfoSection = styled.div`
  grid-area: col2;
  width:100%;
  height: 100%;
`
export const ImageSection = styled.div`
  grid-area: col1;
  width:100%;
  align-items: center;
  position: relative
`
export const ModelImage = styled.img`
  max-width:100%;
  max-height:90%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
`
 export const OnboardingSection  = styled.div`
 height: auto;
 background: #fff;
 display: grid;
 grid-template-columns: 100%;
 align-items: center;
 width: 100%;

`

 export const Section1  = styled.div`
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
 export const Section2  = styled.div`
 align-items: center;
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`

export const OnboardingH2 = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #039F1C;
  text-align: center;
  @media screen and (max-width: 420px) {
    font-size: 1rem;
  }
`

export const OnboardingP = styled.p`
  font-size: 1rem;
  color: #282C47;
  @media screen and (max-width: 420px) {
    font-size: 0.8rem;
  }
  @media screen and (max-width: 1005px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 798px) {
    font-size: 0.9rem;
  }
`

export const OnboardingIcon  = styled.img`
  width: 10%;
  margin-left: auto;
    margin-right: auto;
    display: block;
`
export const OnboardingImage  = styled.img`
  width: 20%;
  margin-left: auto;
    margin-right: auto;
    display: block;

`
export const BriefSection = styled.div`
height: 200px;
display: grid;
grid-template-columns: 100%;
align-items: center;
 `

 export const Section3  = styled.div`
 
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
 export const Section4  = styled.div`
 align-items: center;
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
export const BriefImage  = styled.img`
  width: 20%;
  margin-left: auto;
    margin-right: auto;
    display: block;

`

export const OnboardingH2B = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #282C47;
  text-align: center;
  @media screen and (max-width: 798px) {
    font-size: 1.2rem;
  }
`
export const OnboardingH2P = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0px;
  margin-top: 10px;
  color: #fff;
  text-align: center;
`
export const OnboardingPP = styled.p`
  font-size: 1rem;
  text-align: justify;
  color: #fff;
  line-height:50px;
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`
 export const PriceSection = styled.div`
background: #282C47;
 display: grid;
 grid-template-columns: 100%;
 align-items: center;
 border-bottom-right-radius: 15px;


 @media screen and (max-width: 420px) {
  display: block;
  height: 320px;
}
 `
 export const OBBtnWrapper = styled.div`
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 420px) {
    margin-top: 0px;
  }
`
export const Divider = styled.hr`
border-top: 3px solid #039F1C;
width:60%;
margin-left: 60px;
@media screen and (max-width: 960px) {
  margin-left: 100px;
}
@media screen and (max-width: 768px) {
  margin-left: 0px;
  width:100%
}
`
export const Section5  = styled.div`
 align-items: center;
 width:90%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
 export const Section6  = styled.div`
 align-items: center;
 width:80%;
 margin-left: auto;
 margin-right: auto;
 display: block;

 @media screen and (max-width: 960px) {
 }

 @media screen and (max-width: 420px) {
 }
`
export const OnboardingImageP  = styled.img`
  width: 25%;
  margin-left: auto;
    margin-right: auto;
    display: block;
    @media screen and (max-width: 420px) {
      width: 20%;
      margin-top: 6px;
    }
`

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #2C2C44;
`
export const FormInput = styled.input`
  padding: 4px 4px;
  margin-bottom: 8px;
  width:90%;
  border: 0;
  font-size: 0.7rem;
  border-bottom: 1px solid gray;
  background: transparent;
  outline: 0;
  &:focus{
    box-shadow:none;
    padding-bottom: 6px;  
  font-weight: 400;
  border-width: 1px;
  border-image: linear-gradient(to right, #039F1C,#039F1C);
  border-image-slice: 1;
  }

`

export const BriefForm = styled.div`
display: grid;
grid-template-columns: 50% 50%;
`
export const Status = styled.div`
display: ${({ isShown }) => isShown ? 'block' : 'none'};
 width:100%;
 font-size:0.8rem;
 color:red;
 margin-bottom:2%;
 `
 export const FormButton = styled.button`
  background: #039F1C;
  width: 100%;
  padding: 8px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  -ms-transform: translateY(-50%);
  margin-bottom: 3%;
`