import React from 'react'
import {
  InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper,
  TopLine, Subtitle, CanvasContainer
} from './InfoElements'
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { Earth } from '../earth';
const Info = ({ lightBg, id, imgStart, topLine, lightText, headline, darkText,
  description, description1, description2, alt, img }) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>  
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle darkText={darkText}>{description1}</Subtitle>
                <Subtitle darkText={darkText}>{description2}</Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <CanvasContainer>
                <Canvas>
                  <Suspense fallback={null}>
                    <Earth />
                  </Suspense>
                </Canvas>
              </CanvasContainer>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default Info
