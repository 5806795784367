import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter} from 'react-icons/fa'

import { ImageBg, FooterContainer, FooterWrap, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll'
import Image from '../../images/t-icon.svg'

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
            <ImageBg src={Image} alt=""/>
              tesseract
            </SocialLogo>
            
            <SocialIcons>
              <SocialIconLink href='//www.facebook.com/tesseractmx' target='_blank' arial-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='//www.instagram.com/tesseractmx/' target='_blank' arial-label='Instagram'>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href='//twitter.com/tesseractmx' target='_blank' arial-label='Twitter'>
                <FaTwitter/>
              </SocialIconLink>
              <SocialIconLink href='//www.linkedin.com/company/tesseract-m%C3%A9xico/' target='_blank' arial-label='Linkedin'>
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
            <WebsiteRights to="/">tesseract © {new Date().getFullYear()}.</WebsiteRights>
            <WebsiteRights to="/privacy-policy">Aviso de privacidad.</WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
