import styled from 'styled-components'

export const InfoContainer = styled.div`
  color: #fff;
  background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%,  rgba(40,44,71,1) 100%);
  
`
export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
`
export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(50%, 50%);
  align-items: center;
  grid-template-areas: 'col1 col2';

  @media screen and (max-width: 768px) {
    grid-template-areas: 'col1 col1' 'col2 col2';
  }
`
export const Column1 = styled.div`
  margin-bottom: auto;
  grid-area: col1;
  width:100%;
  height: 100%;
  margin-bottom:3%;
`
export const Column2 = styled.div`
  grid-area: col2;
  width:100%;
  height: 100%;
`
export const TextWrapper = styled.div`
  width:80%;
  margin-left:10%;
  
`
export const TopLine = styled.p`
  color:#4FBA04;
  margin-top: 10%;
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 35px;
  @media screen and (max-width: 320px) {
    font-size: 18px;
  line-height: 18px;
  }
`
export const CanvasContainer = styled.div`
  height: 100%;
  max-width:100%
  @media screen and (max-width: 768px) {
    max-width:90%
  }
`;
export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600px;
  color: rgba(79, 186, 4, 0.8);

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`
export const Subtitle = styled.p`
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  text-align:justify;
`
export const BtnWrap = styled.div`
  display:flex;
  justify-content: flex-start;
`;
export const ImgWrap = styled.div`
  
`;
export const Img = styled.img`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;
