import styled from 'styled-components';

export const FormContent = styled.div`
  display: flex;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`
export const Form = styled.form`
  background-color: #fff;
  max-width: 800px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 0px 32px;
  border-radius:4px;
  box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.9);
  
  @media screen and (max-width: 480px) {
    padding: 32px 32px;
  }
  `
export const FormH1 = styled.h1`
  margin-bottom: 20px;
  color: #2C2C44;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`
export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #2C2C44;
`
export const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 16px;
  border-radius: 4px;
`
export const Button = styled.button`
  background: #2C2C44;
  padding: 10px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  width:50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 32px;
`
export const FormButton = styled.button`
  background: #039F1C;
  width: 100%;
  padding: 12px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  -ms-transform: translateY(-50%);
  margin-bottom: 5%;
`
export const Status = styled.div`
display: ${({ isShown }) => isShown ? 'block' : 'none'};
 width:100%;
 font-size:0.8rem;
 color:red;
 margin-bottom:2%;
 `